import React, { ReactNode } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Circle,
  Flex,
  Icon,
  MenuButton,
  Tooltip,
} from '@chakra-ui/react';
import {
  setJustLoggedIn,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';
import Tag from 'components/Tag';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_SIDEBAR, GA_SIDEBAR_AFTER_LOGIN } from 'utils/constants';
import { CollapsedOptions } from '../CollapsedOptions/CollapsedOptions';
import { dropContent, optionProps } from '../NavButtons';
import { NavText } from '../NavText';

interface NavButtonProps {
  handlePick?: () => void;
  isActive: boolean;
  activeColor: string;
  name: string;
  path: string;
  option: optionProps;
  icon?: ReactNode;
  isCollapsed: boolean;
  isDroppable?: boolean;
  level: number;
  dropOptions?: dropContent[];
  onClose: () => void;
  isNew: boolean;
}

const ICON_W = '24px';

export const NavButton: React.FC<NavButtonProps> = ({
  activeColor,
  isActive,
  handlePick,
  icon,
  isCollapsed,
  isDroppable,
  name,
  option,
  path,
  level,
  dropOptions,
  onClose,
  isNew,
}) => {
  const dispatch = useAppDispatch();
  const justLoggedIn = useAppSelector(state => state.authSlicer.justLoggedIn);

  const analyticsButtonEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_SIDEBAR,
  });

  const analyticsButtonEventAfterLogin = useAnalyticsEvent({
    category: 'User',
    action: GA_SIDEBAR_AFTER_LOGIN,
  });

  const handleClick = () => {
    //Sidebar button Analytics: first menu access after login
    if (justLoggedIn && path) {
      analyticsButtonEventAfterLogin(`${name}`);
      dispatch(setJustLoggedIn(false));
    }

    //Sidebar button Analytics
    if (path) analyticsButtonEvent(`Botão "${name}" Pressionado`);
    if (handlePick) handlePick();
    onClose();
  };

  if (isCollapsed && isDroppable)
    return (
      <>
        <Tooltip label={name} bg="mutable.200" mt="-25px">
          <MenuButton>
            <Button
              as={Button}
              onClick={handlePick}
              minW={0}
              minH={0}
              py="26px"
              w="100%"
              _active={{ bg: activeColor, color: 'white', textColor: 'white' }}
              justifyContent="start"
              fontWeight={400}
              my="8px"
              isActive={isActive}
            >
              {level === 0 ? (
                <Flex>{icon}</Flex>
              ) : level === 1 ? (
                <Circle
                  bg="white"
                  opacity={isActive ? '100%' : '50%'}
                  size="10px"
                  ml="12px"
                  mr="20px"
                />
              ) : (
                <Flex py="10px" px="16px" borderRadius="8px" align="center">
                  <Circle
                    bg="white"
                    opacity={isActive ? '100%' : '50%'}
                    size="8px"
                    ml="24px"
                    mr="8px"
                  />
                </Flex>
              )}
            </Button>
          </MenuButton>
        </Tooltip>

        <CollapsedOptions dropOptions={dropOptions} />
      </>
    );

  if (isCollapsed && !isDroppable)
    return (
      <Tooltip label={name} bg="mutable.200">
        <NavLink to={path}>
          <Button
            as={Button}
            onClick={handleClick}
            minW={0}
            minH={0}
            py="26px"
            w="100%"
            _active={{ bg: activeColor, color: 'white', textColor: 'white' }}
            justifyContent="start"
            alignItems="center"
            fontWeight={400}
            my="8px"
            isActive={isActive}
          >
            {level === 0 ? (
              <Flex>{icon}</Flex>
            ) : level === 1 ? (
              <Circle
                bg="white"
                opacity={isActive ? '100%' : '50%'}
                size="10px"
                ml="12px"
                mr="20px"
              />
            ) : (
              <Flex py="10px" px="16px" borderRadius="8px" align="center">
                <Circle
                  bg="white"
                  opacity={isActive ? '100%' : '50%'}
                  size="8px"
                  ml="24px"
                  mr="8px"
                />
              </Flex>
            )}
          </Button>
        </NavLink>
      </Tooltip>
    );

  if (isNew) icon = <Tag label="Novo" />;

  if (!isDroppable)
    return (
      <NavLink
        to={path}
        target={name === 'Vamos Controle' ? '_blank' : undefined}
      >
        <Button
          onClick={handleClick}
          minW={0}
          minH={0}
          py="26px"
          w="100%"
          _active={{ bg: activeColor, color: 'white', textColor: 'white' }}
          justifyContent="start"
          fontWeight={400}
          my="8px"
          isActive={isActive}
          pl={isNew && level === 0 ? '4px' : '16px'}
        >
          {level === 0 ? (
            isCollapsed ? (
              <Flex bg="yellow">{icon}</Flex>
            ) : (
              <Box pt="4px" pr={isNew ? '4px' : '16px'}>
                {icon}
              </Box>
            )
          ) : level === 1 ? (
            <Circle
              bg="white"
              opacity={isActive ? '100%' : '50%'}
              size="10px"
              ml="0px"
              mr="20px"
            />
          ) : (
            <Flex py="10px" borderRadius="8px" align="center">
              <Circle
                bg="white"
                opacity={isActive ? '100%' : '50%'}
                size="8px"
                ml="24px"
                mr="20px"
              />
            </Flex>
          )}

          {!isCollapsed && <NavText name={name} isActive={isActive} />}
        </Button>
      </NavLink>
    );

  return (
    <Button
      onClick={handlePick}
      minW={0}
      minH={0}
      py="26px"
      w="100%"
      _active={{ bg: activeColor, color: 'white', textColor: 'white' }}
      justifyContent="start"
      fontWeight={400}
      my="8px"
      isActive={isActive}
      pl={isNew ? '4px' : '16px'}
    >
      {level === 0 ? (
        <Box pt="4px" pr={isNew ? '4px' : '16px'}>
          {icon}
        </Box>
      ) : level === 1 ? (
        <Circle
          bg="white"
          opacity={isActive ? '100%' : '50%'}
          size="10px"
          ml="12px"
          mr="20px"
        />
      ) : (
        <Flex py="10px" px="16px" borderRadius="8px" align="center">
          <Circle
            bg="white"
            opacity={isActive ? '100%' : '50%'}
            size="8px"
            ml="24px"
            mr="8px"
          />
        </Flex>
      )}

      <NavText name={name} isActive={isActive} />

      <Icon
        as={IoIosArrowDown}
        h="14px"
        w={ICON_W}
        color="white"
        alignSelf="center"
        ml="auto"
        mt="6px"
        transform={
          level === 0
            ? option.fp.includes(name)
              ? 'rotate(-180deg)'
              : 'rotate(-360deg)'
            : option.sp.includes(name)
            ? 'rotate(-180deg)'
            : 'rotate(-360deg)'
        }
        transition="width .5s ease"
      />
    </Button>
  );
};
