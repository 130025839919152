import React, { useEffect, useState, ReactNode, useMemo } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import {
  Flex,
  Text,
  Button,
  HStack,
  FlexProps,
  Box,
  Select,
} from '@chakra-ui/react';
import {
  CardOptionsMenu,
  ErrorCard,
  HorizontalBar,
  Loading,
  MapsSvg,
  SegmentedPicker,
} from 'components';
import { MapSkeleton } from 'components/Skeletons';
import { useSegmentedPicker } from 'hooks/useSegmentedPicker';
import { DETAILS_LABEL } from 'utils/constants';
import { Card } from './Card';

interface MultiInfoCardProps extends FlexProps {
  title?: string;
  customTitle?: ReactNode;
  subtitleMb?: string | number;
  subtitle?: string;
  hasFilter?: boolean;
  select?: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
  selected?: string;
  hasBars?: boolean;
  barsColor?: string;
  picker?: string[];
  setPicked?: React.Dispatch<React.SetStateAction<string>>;
  firstPicked?: string;
  details?: () => void;
  hasMap?: boolean;
  mapType?: string;
  mapW?: string;
  mapH?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapColor?: any;
  data: {
    total?: number;
    values: { label: string; value: number }[];
  };
  fromBrazil?: boolean;
  loading?: boolean;
  isMapClickable?: boolean;
  cardName?: string;
  isError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch?: any;
  cardOptions?: CardOptions;
  cardRef?: CardRef;
  fetching?: boolean;
}

export const MapCard: React.FC<MultiInfoCardProps> = ({
  title,
  customTitle,
  subtitleMb,
  hasFilter,
  hasMap,
  mapH,
  mapW,
  mapColor,
  mapType,
  picker,
  details,
  subtitle,
  data,
  select,
  setSelected,
  selected,
  hasBars,
  setPicked,
  firstPicked,
  fromBrazil,
  barsColor,
  loading,
  fetching,
  isMapClickable,
  cardName,
  isError,
  refetch,
  cardOptions,
  cardRef,
  ...rest
}) => {
  const { onSegmentedPickerChange } = useSegmentedPicker(setPicked);

  const [currentSelected, setCurrentSelected] = useState(selected || 'Brasil');

  const { biggestLabel, totalValue, bigNumberLength } = useMemo(() => {
    if (
      !(Object.keys(data).length > 0) ||
      !data?.values ||
      !(data?.values.length > 0)
    ) {
      return { biggestLabel: '', totalValue: 0, bigNumberLength: '0px' };
    }

    const valuesAux = [...data.values];

    const biggestLabel = valuesAux.sort(
      (a, b) => b.label.length - a.label.length,
    )[0];

    const totalValue = valuesAux.reduce(
      (sum, value) => (value.label !== 'Total' ? sum + value.value : sum),
      0,
    );

    return {
      biggestLabel: biggestLabel.label,
      totalValue,
      bigNumberLength: `${String(totalValue).length * 9}px`,
    };
  }, [data]);

  useEffect(() => {
    if (select && setSelected) setSelected(currentSelected);
  }, [currentSelected]);

  if (isError)
    return (
      <ErrorCard
        cardOptions={cardOptions}
        cardRef={cardRef}
        dragHandler={cardOptions?.dragHandler}
        {...rest}
        refetch={() => (refetch ? refetch() : null)}
      />
    );

  if (loading)
    return (
      <Card
        p="0px"
        cardOptions={cardOptions}
        cardRef={cardRef}
        dragHandler={cardOptions?.dragHandler}
        {...rest}
      >
        <MapSkeleton />
      </Card>
    );

  return (
    <Card
      cardOptions={cardOptions}
      cardRef={cardRef}
      dragHandler={cardOptions?.dragHandler}
      {...rest}
    >
      {fetching && (
        <Flex position="absolute" zIndex={2} alignSelf="center">
          <Loading />
        </Flex>
      )}
      <Flex flexDir="column" opacity={fetching ? '0.5' : '1'} h="100%">
        <Flex justifyContent="space-between">
          {customTitle ? (
            customTitle
          ) : (
            <Text
              alignSelf="center"
              color="text.100"
              fontSize="16px"
              fontWeight={400}
            >
              {title}
            </Text>
          )}

          <HStack flexWrap="wrap" justify="flex-end">
            <HStack
              display={{ base: 'none', md: 'flex' }}
              ml="16px"
              spacing="16px"
            >
              {select && (
                <Select
                  _focus={{ borderColor: 'blue.100' }}
                  data-cy={`select-${cardName}`}
                  h="32px"
                  w="150px"
                  value={currentSelected}
                  onChange={e => setCurrentSelected(e.target.value)}
                  aria-label="Selecionar região"
                >
                  <option aria-label="Brasil" value="Brasil">
                    Brasil
                  </option>
                  {select.map((option, index) => (
                    <option aria-label={option} key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
              {hasFilter && <Text>Filtro de data</Text>}

              {!!picker && (
                <SegmentedPicker
                  pickerName={cardName}
                  firstSelectedLabel={firstPicked}
                  ref={onSegmentedPickerChange}
                  labels={picker}
                />
              )}
            </HStack>
            <HStack ml="16px" spacing="16px">
              {!!details && (
                <Button
                  display={{ md: 'flex', base: 'none' }}
                  data-cy={`details-${cardName}`}
                  onClick={details}
                  variant="link"
                  rightIcon={<VscLinkExternal />}
                >
                  {DETAILS_LABEL}
                </Button>
              )}
              {!!cardOptions && <CardOptionsMenu values={cardOptions} />}
            </HStack>
          </HStack>
        </Flex>
        <Text
          textAlign="start"
          mb={{ base: '8px', md: subtitleMb || '35px' }}
          color="text.300"
          fontSize="12px"
          fontWeight={500}
        >
          {subtitle || ''}
        </Text>

        <HStack
          display={{ sm: 'none', base: 'flex' }}
          justifyContent="space-between"
          mb="18px"
        >
          {!!details && (
            <Button
              display={{ sm: 'none', base: 'flex' }}
              data-cy={`details-${cardName}`}
              onClick={details}
              variant="link"
              rightIcon={<VscLinkExternal />}
            >
              {DETAILS_LABEL}
            </Button>
          )}
        </HStack>

        <HStack
          mb={select || picker ? '18px' : '0px'}
          display={{ base: 'flex', md: 'none' }}
          spacing="32px"
        >
          {select && (
            <Select
              data-cy={`select2-${cardName}`}
              h="32px"
              w="150px"
              value={currentSelected}
              onChange={e => setCurrentSelected(e.target.value)}
              aria-label="Selecionar região"
            >
              <option aria-label="Brasil" value="Brasil">
                Brasil
              </option>
              {select.map((option, index) => (
                <option aria-label={option} key={index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          )}
          {hasFilter && <Text>Filtro de data</Text>}

          {!!picker && (
            <SegmentedPicker
              firstSelectedLabel={firstPicked}
              ref={onSegmentedPickerChange}
              labels={picker}
            />
          )}
        </HStack>

        {data?.values && (
          <Flex
            flex={1}
            // h="100%"
            flexDirection={{ base: 'column', md: 'row' }}
            align="center"
          >
            {hasMap && (
              <MapsSvg
                mapColor={mapColor}
                mapH={mapH}
                mapW={mapW}
                mapType={mapType}
                fromBrazil={fromBrazil}
                isClickable={isMapClickable}
                setSelected={select ? setCurrentSelected : setSelected}
              />
            )}

            <Box
              mt={{ base: '34px', md: '0px' }}
              w="100%"
              justifyContent="center"
              alignItems="center"
            >
              {data.values.map((value, index) => {
                if (value.label !== 'Total')
                  return (
                    <HStack
                      spacing={hasBars ? '16px' : '10px'}
                      key={index}
                      mb="16px"
                    >
                      <Text
                        textAlign="start"
                        fontSize="12px"
                        color="text.200"
                        minW={{
                          base: '40px',
                          md: hasBars
                            ? biggestLabel.length * 6.59 + 'px'
                            : undefined,
                        }}
                      >
                        {value.label}
                      </Text>

                      {hasBars ? (
                        <HorizontalBar
                          h="20px"
                          w="100%"
                          total={data.total ? data.total : 0}
                          value={value.value}
                          color={barsColor}
                        />
                      ) : (
                        <Flex
                          h={0}
                          flex={1}
                          border="dashed 1px"
                          borderColor="grey.200"
                        />
                      )}
                      <Text
                        fontSize="14px"
                        fontWeight={500}
                        textAlign="center"
                        color="text.100"
                        minW={bigNumberLength}
                      >
                        {value.value || 0}
                      </Text>
                    </HStack>
                  );
              })}
              <HStack spacing={hasBars ? '16px' : '10px'} mb="16px">
                <Text
                  textAlign="start"
                  fontSize="12px"
                  color="text.200"
                  fontWeight={800}
                  minW={{
                    base: '40px',
                    md: hasBars ? biggestLabel.length * 6.59 + 'px' : undefined,
                  }}
                >
                  Total
                </Text>

                {hasBars ? (
                  <HorizontalBar
                    h="20px"
                    w="100%"
                    total={totalValue}
                    value={totalValue}
                    color={barsColor}
                  />
                ) : (
                  <Flex
                    h={0}
                    flex={1}
                    border="dashed 1px"
                    borderColor="grey.200"
                  />
                )}
                <Text
                  fontSize="14px"
                  fontWeight={500}
                  textAlign="center"
                  color="text.100"
                  minW={bigNumberLength}
                >
                  {totalValue || 0}
                </Text>
              </HStack>
            </Box>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
