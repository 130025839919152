import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import { BoxProps, Button, HStack } from '@chakra-ui/react';

interface SegmentedPickerProps extends BoxProps {
  firstSelectedLabel?: string;
  labels: string[];
  pickerName?: string;
}

const SegmentedPicker = forwardRef<string | null, SegmentedPickerProps>(
  (props, ref) => {
    const { labels, firstSelectedLabel, pickerName, ...rest } = props;
    const [picked, setPicked] = useState(firstSelectedLabel || labels[0]);

    useEffect(() => {
      if (firstSelectedLabel) setPicked(firstSelectedLabel);
    }, [firstSelectedLabel]);

    useImperativeHandle(ref, () => picked, [picked]);

    return (
      <HStack
        {...rest}
        spacing={2}
        bg="grey.200"
        px="8px"
        py="4px"
        borderRadius="4px"
        justifyContent="center"
      >
        {labels.map((label, index) => {
          return (
            <Button
              aria-label={`Mudar filtro para ${label}`}
              date-cy={`picker-${pickerName}-${label}`}
              key={index}
              color="text.300"
              bg={picked === label ? 'white' : 'none'}
              boxShadow={
                picked === label
                  ? '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)'
                  : 'none'
              }
              fontSize="12px"
              fontWeight={400}
              _hover={{ bg: 'white' }}
              onClick={() => setPicked(label)}
              h="28px"
              w="52px"
            >
              {label}
            </Button>
          );
        })}
      </HStack>
    );
  },
);

SegmentedPicker.displayName = 'SegmentedPicker';

export default SegmentedPicker;
