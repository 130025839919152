import React, { useMemo, useEffect, useState } from 'react';
import {
  AiOutlineDoubleLeft,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleRight,
} from 'react-icons/ai';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Button,
  HStack,
  Select,
  Text,
  IconButton,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { useAppSelector } from '@spartalabs/pdc-core';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  PaginationState,
} from '@tanstack/react-table';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { Loading } from 'components/Loading';
import { TableSkele } from 'components/Skeletons/DataTable';
import Tag from 'components/Tag';
import { useAnalyticsEvent } from 'hooks';
import { GA_TABLE_LABEL } from 'utils/constants';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataTable: React.FC<DataTableProps<any | EntranceExitTable>> = ({
  labels,
  isPaginated = true,
  data,
  customColumns = null,
  loading,
  fetchData,
  searchTerm,
  selected,
  region,
  startDate,
  endDate,
  refetch,
  isError,
  selectedOptions,
  filter,
  filtredOption,
  analyticsId,
  error,
  openToken,
  hasApprovers,
  ...rest
}) => {
  const currentDivisionId = useAppSelector(
    state => state.authSlicer.user.currentDivisionId,
  );

  const [sorting, setSorting] = useState<
    { column: number; direction: string } | undefined
  >();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_TABLE_LABEL,
  });

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 5,
    });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [data],
  );

  const defaultColumns = useMemo(() => {
    if (labels && labels.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataColumns: ColumnDef<Data, any>[] = labels.map(label => ({
        header: label.title,
        accessorKey: label.accessor,
        enableSorting: !label.disableSorting,
        cell: info =>
          label.type === 'button' ? (
            <Button
              onClick={() =>
                label.ButtonFn &&
                label.ButtonFn({
                  id: info.row.getValue('id'),
                })
              }
            >
              {info.getValue()}
            </Button>
          ) : label.type === 'tag' ? (
            <Tag label={info.getValue()} />
          ) : label.type === 'custom' ? (
            label.component &&
            label.component(info.row.getValue('id'), selected)
          ) : (
            info.getValue()
          ),
      }));

      const columns = [...dataColumns];

      return columns;
    }

    return [];
  }, [labels, selected]);

  const hiddenColumns =
    labels && labels.length > 0
      ? Object.fromEntries(
          new Map(labels.map(label => [label.accessor, label.isShown ?? true])),
        )
      : {};

  const table = useReactTable({
    columns: customColumns ?? defaultColumns,
    data: data?.itens || [],
    pageCount: data?.pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    initialState: {
      columnVisibility: { ...hiddenColumns },
      pagination: { pageSize: isPaginated ? 5 : 9999 },
    },
    state: {
      pagination,
    },
  });

  const handleFetch = () => {
    if (fetchData) {
      if (selected || selectedOptions) {
        if (
          selected &&
          selectedOptions?.flatMap(item => item.name).includes(selected.name)
        ) {
          fetchData({
            page: pageIndex + 1,
            quantity: pageSize,
            term: searchTerm,
            region: region,
            sorting,
            selected: selected?.id,
            startDate,
            endDate,
            filter,
            filtredOption,
          });
        }
      } else
        fetchData({
          page: pageIndex + 1,
          quantity: pageSize,
          term: searchTerm,
          region: region,
          sorting,
          startDate,
          endDate,
          filter,
          filtredOption,
        });
    }
  };

  useEffect(() => {
    handleFetch();
  }, [
    selectedOptions,
    selected,
    pageIndex,
    pageSize,
    searchTerm,
    sorting,
    startDate,
    endDate,
    currentDivisionId,
    filter,
    filtredOption,
  ]);

  useEffect(() => {
    setPagination(prev => ({ pageIndex: 0, pageSize: prev.pageSize }));
  }, [searchTerm, startDate, endDate, currentDivisionId]);

  useEffect(() => {
    table.setPageIndex(0);
  }, [fetchData]);

  if (isError)
    return (
      <ErrorCard
        hasApprovers={hasApprovers}
        openToken={openToken}
        error={error}
        shadow={0}
        border="0px"
        {...rest}
        refetch={() => (refetch ? handleFetch() : null)}
      />
    );

  return (
    <VStack w={rest.w} h={rest.h} spacing="24px" align="start">
      <Flex w="100%" overflow="auto">
        {!data ? (
          <Flex overflowX="auto" position="relative" w="100%">
            <TableSkele labels={labels} />
          </Flex>
        ) : (
          <Flex w="100%" position="relative">
            {loading && (
              <Flex justify="center" position="absolute" w="100%" h="100%">
                <Loading />
              </Flex>
            )}
            <Table opacity={loading ? '50%' : '100%'} w="100%">
              <Thead
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor="grey.200"
                bg="grey.400"
              >
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const meta: any = header.column.columnDef.meta;
                      return (
                        <Th
                          fontWeight={400}
                          fontSize="12px"
                          color="black"
                          borderColor="grey.200"
                          key={header.id}
                          onClick={() => {
                            header.column.getCanSort() &&
                              setSorting(prev => ({
                                column: index,
                                direction:
                                  prev?.column !== index
                                    ? 'asc'
                                    : prev.direction === 'asc'
                                    ? 'desc'
                                    : 'asc',
                              }));
                          }}
                          isNumeric={meta?.isNumeric}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}

                          <chakra.span pl="4">
                            {sorting?.column === index ? (
                              sorting.direction === 'desc' ? (
                                <TriangleDownIcon aria-label="ordenado descendente" />
                              ) : (
                                <TriangleUpIcon aria-label="ordenado ascendente" />
                              )
                            ) : null}
                          </chakra.span>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {data && data.itens.length > 0 ? (
                  table.getRowModel().rows.map(row => (
                    <Tr
                      key={row.id}
                      borderLeft="1px solid"
                      borderRight="1px solid"
                      borderColor="grey.200"
                    >
                      {row.getVisibleCells().map(cell => {
                        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const meta: any = cell.column.columnDef.meta;
                        return (
                          <Td
                            borderColor="grey.200"
                            key={cell.id}
                            isNumeric={meta?.isNumeric}
                            fontSize="12px"
                            color="grey.600"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  ))
                ) : (
                  <Tr
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderColor="grey.200"
                  >
                    <Td
                      colSpan={labels && labels.length}
                      borderColor="grey.200"
                      fontSize="12px"
                      color="grey.600"
                      textAlign="center"
                    >
                      Não foram encontradas informações
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
        )}
      </Flex>
      <HStack
        flexDir={{ base: 'column', sm: 'row' }}
        w="100%"
        justify="space-between"
      >
        <Text color="text.300" fontSize="14px">
          Mostrando de {table.getState().pagination.pageIndex * pageSize + 1}{' '}
          até{' '}
          {data &&
          (table.getState().pagination.pageIndex + 1) * pageSize < data.rows
            ? (table.getState().pagination.pageIndex + 1) * pageSize
            : data?.rows}{' '}
          de {data?.rows} registros
        </Text>
        {isPaginated && (
          <Flex>
            <Select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
              color="grey.600"
              aria-label="Quantidade de linhas"
            >
              {[5, 10, 15, 20, 25].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Linhas
                </option>
              ))}
            </Select>

            <IconButton
              icon={<AiOutlineDoubleLeft />}
              aria-label="Primeira página"
              variant="ghost"
              color="grey.600"
              onClick={() => {
                analyticsEvent(`'Navegou na tabela de ${analyticsId}`);
                table.setPageIndex(0);
              }}
              isDisabled={loading || !table.getCanPreviousPage()}
            />
            <IconButton
              icon={<AiOutlineLeft />}
              aria-label="Voltar página"
              variant="ghost"
              color="grey.600"
              onClick={() => {
                analyticsEvent(`'Navegou na tabela de ${analyticsId}`);
                table.previousPage();
              }}
              isDisabled={loading || !table.getCanPreviousPage()}
            />
            <IconButton
              icon={<AiOutlineRight />}
              aria-label="Avançar página"
              variant="ghost"
              color="grey.600"
              onClick={() => {
                analyticsEvent(`'Navegou na tabela de ${analyticsId}`);
                table.nextPage();
              }}
              isDisabled={loading || !table.getCanNextPage()}
            />
            <IconButton
              icon={<AiOutlineDoubleRight />}
              aria-label="Última página"
              variant="ghost"
              color="grey.600"
              onClick={() => {
                analyticsEvent(`'Navegou na tabela de ${analyticsId}`);
                table.setPageIndex(table.getPageCount);
              }}
              isDisabled={loading || !table.getCanNextPage()}
            />
          </Flex>
        )}
      </HStack>
    </VStack>
  );
};
