import { AiFillAccountBook, AiFillAlert } from 'react-icons/ai';
import { BiTrafficCone } from 'react-icons/bi';
import { BsShieldCheck } from 'react-icons/bs';
import { IoIosSettings, IoMdGlobe } from 'react-icons/io';
import { MdOutlineBuild } from 'react-icons/md';
import { colors } from 'theme/components/colors';

export const SIZE_CHANGE = 847;

export const DETAILS_LABEL = 'Ver Detalhes';

export const GA4_KEY = 'G-405WD6LC7R';

//Ga Label # custom
export const GA_CUSTOMIZE_WIDGETS_LABEL = 'Personalização de Widgets';
export const CUSTOMIZE_COLOR_LABEL = 'Personalização de Cor';
export const CUSTOMIZE_LABEL = 'Personalização de Marca';

//Ga Label # onboarding
export const GA_ONBOARDING = 'Onboarding';
export const GA_GUIDED_TOUR = 'Tour Guiado';

//Ga Label # PageAction
export const GA_DOWNLOAD_LABEL = 'Baixar planilha';
export const GA_NEW_SCHEDULE_LABEL = 'Novo Agendamento';
export const GA_FINANCIAL_LABEL = 'Faturamento';
export const GA_FLEETDIST_LABEL = 'Distribuição da frota';
export const GA_FAQ_LABEL = 'Central de ajuda';
export const GA_TABLE_LABEL = 'Tabelas';
export const GA_SIDEBAR = 'Menu Lateral';
export const GA_VEHICLE = 'Veículos';
export const GA_Fines = 'Multas';
export const GA_SIDEBAR_AFTER_LOGIN =
  'Primeiro botão do menu lateral após login';
export const GA_LOGIN = 'Login';

export const services = [
  { id: '0', name: 'PNEU' },
  { id: '1', name: 'MANUTENÇÃO CORRETIVA' },
  { id: '2', name: 'REVISÃO PREVENTIVA' },
  { id: '3', name: 'PREVENTIVA DE IMPLEMENTO' },
  { id: '4', name: 'TACÓGRAFO' },
  { id: '5', name: '2º VIA DE PLACA' },
  { id: '6', name: 'SINISTRO' },
  { id: '7', name: 'DOCUMENTAÇÃO' },
  { id: '8', name: 'TESTE ACÚSTICO' },
  { id: '9', name: 'TESTE ELÉTRICO' },
  { id: '10', name: 'TESTE DE OPACIDADE' },
  { id: '11', name: 'GARANTIA' },
  { id: '12', name: 'PÓS IMPLANTAÇÃO' },
  { id: '13', name: 'REVISÃO DE ASSENTAMENTO' },
  { id: '14', name: 'FURTO/ROUBO VEÍCULO' },
  { id: '15', name: 'FURTO/ROUBO ACESSÓRIO' },
];

export const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

interface MonthToNumProps {
  [month: string]: string;
}

export const monthsToNum: MonthToNumProps = {
  Jan: '01',
  Fev: '02',
  Mar: '03',
  Abr: '04',
  Mai: '05',
  Jun: '06',
  Jul: '07',
  Ago: '08',
  Set: '09',
  Out: '10',
  Nov: '11',
  Dez: '12',
};

export const ChartTypeFilters: ChartTypeFilter[] = [
  {
    label: 'Serviços Total',
    icon: IoMdGlobe,
    type: 'total',
  },
  {
    label: 'Serviços Preventivos',
    icon: BsShieldCheck,
    type: 'prev',
  },
  {
    label: 'Serviços Corretivos',
    icon: MdOutlineBuild,
    type: 'corr',
  },
  {
    label: 'Serviços Sinistro',
    icon: BiTrafficCone,
    type: 'sin',
  },
  {
    label: 'Serviços Outros',
    icon: IoIosSettings,
    type: 'others',
  },
];

//#region <MOCKED TESTS>
export const MapValue1 = {
  total: 141,
  values: [
    { label: 'Até 12 meses', value: 98 },
    { label: '13 - 24 meses', value: 32 },
    { label: '25 -  36 meses', value: 11 },
    { label: '37 - 48 meses', value: 10 },
    { label: '49 - 60 meses', value: 5 },
    { label: '+60 meses', value: 2 },
  ],
};

export const MapValue3 = {
  total: 53,
  values: [
    { label: 'Até 12 meses', value: 22 },
    { label: '13 - 24 meses', value: 10 },
    { label: '25 -  36 meses', value: 8 },
    { label: '37 - 48 meses', value: 11 },
    { label: '49 - 60 meses', value: 1 },
    { label: '+60 meses', value: 2 },
  ],
};

export const MapValue2 = {
  total: 141,
  values: [
    { label: 'Total', value: 141 },
    { label: 'Sudeste', value: 98 },
    { label: 'Norte', value: 41 },
    { label: 'Nordeste', value: 25 },
    { label: 'Sul', value: 12 },
  ],
};

export const MapValue4 = {
  total: 141,
  values: [
    { label: 'Total', value: 141 },
    { label: 'Sudeste', value: 98 },
    { label: 'Sul', value: 31 },
    { label: 'Nordeste', value: 25 },
    { label: 'Norte', value: 10 },
    { label: 'Centro-Oeste', value: 12 },
  ],
};

export const homeCardsData = {
  fleetData: [
    {
      label: 'Disponíveis',
      value: 95,
      percent: 2.0,
    },
    {
      label: 'Indisponíveis',
      value: 12,
      percent: -1.7,
    },
  ],
  rentalData: {
    value: 30457.54,
    percent: 1.7,
  },
  ticketData: {
    value: 14471,
    percent: -2.6,
  },
  maintenanceData: {
    value: 10474.69,
    percent: 1.4,
  },
  totalValueData: {
    value: 60457.54,
    percent: -4.7,
  },
  MultiInfoData1: {
    title: 'Atendimentos realizados em manutenções por tipos de serviços',
    subtitle:
      'Separada em cada estado como estão distribuídos por idade e km a sua frota',
    hasFilter: true,
    details: 'detalhes',
    picker: ['Dia', 'Mês'],
    // data: [
    //   {
    //     icon: IoMdGlobe,
    //     label: 'Total',
    //     text: 'da frota',
    //     value: 15,
    //     total: 15,
    //   },
    //   {
    //     icon: BsShieldCheck,
    //     label: 'Preventivos',
    //     text: 'da frota',
    //     value: 4,
    //     total: 15,
    //   },
    //   {
    //     icon: MdOutlineBuild,
    //     label: 'Corretivos',
    //     text: 'da frota',
    //     value: 11,
    //     total: 15,
    //   },
    //   {
    //     icon: BiTrafficCone,
    //     label: 'Sinistro',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    //   {
    //     icon: IoIosSettings,
    //     label: 'Outros',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    // ],
    data: {
      count: {
        total: 15,
        corrective: 15,
        preventive: 15,
        sinister: 15,
        others: 15,
      },
    },
  },
  MultiInfoData2: {
    title: 'Atendimentos realizados em manutenções por tipos de serviços',
    subtitle:
      'Separada em cada estado como estão distribuídos por idade e km a sua frota',
    hasFilter: false,
    details: 'detalhes',
    // data: [
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 1',
    //     text: 'da frota',
    //     value: 15,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 2',
    //     text: 'da frota',
    //     value: 4,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 3',
    //     text: 'da frota',
    //     value: 11,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 4',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 5',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 6',
    //     text: 'da frota',
    //     value: 11,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 7',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    //   {
    //     icon: AiFillCar,
    //     label: 'Carro 8',
    //     text: 'da frota',
    //     value: 0,
    //     total: 15,
    //   },
    // ],
    data: {
      count: {
        total: 200,
        carrinho1: 15,
        carrinho2: 15,
        carrinho3: 15,
        carrinho4: 15,
        carrinho5: 15,
      },
    },
  },
  MultiInfoData3: {
    title: 'Atendimentos realizados em manutenções por tipos de serviços',
    subtitle:
      'Separada em cada estado como estão distribuídos por idade e km a sua frota',
    hasFilter: false,
    picker: ['Dia', 'Mês'],
    details: 'detalhes',
    data: [
      {
        icon: AiFillAccountBook,
        label: 'Serviços Total',
        text: 'da frota',
        value: 15,
        total: 15,
      },
      {
        icon: AiFillAlert,
        label: 'Serviços Preventivos',
        text: 'da frota',
        value: 4,
        total: 15,
      },
    ],
  },
};

export const defaultColorArray = [
  colors.yellow['100'],
  colors.orange['100'],
  colors.blue['100'],
  colors.grey['600'],
  colors.green['100'],
];

export const testLabels = ['a', 'b', 'c', 'd', 'e', 'f'];

export const monthsLabels = [
  'Set/2022',
  'Out/2022',
  'Nov/2022',
  'Dez/2022',
  'Jan/2023',
  'Fev/2023',
  'Mar/2023',
  'Abr/2023',
  'Mai/2023',
  'Jun/2023',
  'Jul/2023',
  'Ago/2023',
];
export const daysLabels = [
  '01/03',
  '02/03',
  '03/03',
  '04/03',
  '05/03',
  '06/03',
  '07/03',
  '08/03',
  '09/03',
  '10/03',
  '11/03',
  '12/03',
  '13/03',
  '14/03',
  '15/03',
  '16/03',
  '17/03',
  '18/03',
  '19/03',
  '20/03',
  '21/03',
  '22/03',
  '23/03',
  '24/03',
  '25/03',
  '26/03',
  '27/03',
  '28/03',
  '29/03',
  '30/03',
];

export const emptyData: ChartData[] = [
  {
    label: '',
    values: [0],
  },
];

export const testTableData = (value: number) => {
  const data: TableData = {
    itens: [],
    pageCount: 100,
    rows: 100,
  };
  for (let index = 0; index < value; index++) {
    data.itens.push({
      id: `BSX7H41${index}`,
      plate: ['BRA2E19', 'DFD7DF4', 'SDF7G74', 'HUT5A23', 'TRA6G87'][
        Math.floor(Math.random() * 5)
      ],
      model: 'Semi Reboque',
      entryDate: '24/03/2023',
      exitDate: '24/03/2023',
      backupCar: '-',
    });
  }

  return data;
};

export const testTableLabels = [
  {
    title: 'Nº de atendimento',
    type: 'data',
    accessor: 'id',
  },
  {
    title: 'Placas',
    type: 'data',
    accessor: 'plate',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Data de Entrada',
    type: 'text',
    accessor: 'entryDate',
  },
  {
    title: 'Data de Saída',
    type: 'text',
    accessor: 'exitDate',
  },
  {
    title: 'Carro reserva',
    type: 'text',
    accessor: 'backupCar',
  },
];

export const tableFilters = [
  {
    label: 'Nº de atendimento',
    type: 'text',
    placeholder: 'Todas',
    accessor: 'id',
  },
  {
    label: 'Placas',
    type: 'plate',
    placeholder: 'ex:AAA0A00',
    accessor: 'plate',
  },
  {
    label: 'Modelo',
    type: 'text',
    placeholder: 'modelo',
    accessor: 'model',
  },
  {
    label: 'Data de Entrada',
    type: 'date',
    placeholder: 'Data de Entrada',
    accessor: 'entryDate',
  },
  {
    label: 'Data de Saída',
    type: 'date',
    placeholder: 'Data de Saída',
    accessor: 'exitDate',
  },
  {
    label: 'Carro reserva',
    type: 'text',
    placeholder: '------',
    accessor: 'backupCar',
  },
  {
    label: 'Carro reserva',
    type: 'text',
    placeholder: '------',
    accessor: 'backupCar',
  },
];

export const detailMock = {
  service: 'VERIFICAR TROCA DE OLEO FILTROS',
  type: 'REVISÃO PREVENTIVA',
  status:
    'ENCAMINHADO PARA Savar Veiculos Ltda 92889070000756 Savar Veiculos Ltda Giuseppe',
  address:
    'Mattea, 625 - Fragata, Pelotas - Rio Grande do Sul, 96050-080 Concessionária',
};

export const productsMock = [
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 1,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 2,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Peça',
    desc: 'SAPATA FREIO DIANT (PARA)',
    quant: 100,
    finality: 'PECAS E ACESSORIOS - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 3,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 4,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 8,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 12,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
  {
    type: 'Mão de Obra',
    desc: '1401 MO - MECANICA',
    quant: 14,
    finality: 'MANUTENCAO/CONSERVACAO - FROTA',
  },
];

export const tableMock: ServiceTableProps[] = [];

for (let index = 0; index < 50; index++) {
  tableMock.push({
    id: `BSX7H41${index}`,
    plate: ['BRA2E19', 'DFD7DF4', 'SDF7G74', 'HUT5A23', 'TRA6G87'][
      Math.floor(Math.random() * 5)
    ],
    scheduleStatus: 'Atendimento Finalizado',
    reason: 'Preventivo',
    scheduleDay: '24/03/2023',
    previsionDate: '24/03/2023',
    exitDate: '24/03/2023',
    status: 'Rodando',
    details: detailMock,
    products: productsMock,
  });
}

export const ServiceTable = [
  {
    title: 'Nº de atendimento',
    type: 'data',
    accessor: 'id',
  },
  {
    title: 'Placas',
    type: 'data',
    accessor: 'plate',
  },
  {
    title: 'Status do atendimento',
    type: 'text',
    accessor: 'status',
  },
  {
    title: 'Motivo',
    type: 'text',
    accessor: 'type',
  },
  {
    title: 'Data de Agendamento',
    type: 'text',
    accessor: 'scheduleDate',
  },
  {
    title: 'Data de Previsão',
    type: 'text',
    accessor: 'previsionDate',
  },
  {
    title: 'Data de Saída',
    type: 'text',
    accessor: 'exitDate',
  },
  {
    title: 'Situação do Veículo',
    type: 'text',
    accessor: 'situation',
  },
  {
    title: 'Mais Detalhes',
    type: 'text',
    accessor: 'details',
  },
];

export const ServiceFilters = [
  {
    label: 'Nº de atendimento',
    type: 'text',
    placeholder: 'Todas',
    accessor: 'id',
  },
  {
    label: 'Placas',
    type: 'plate',
    placeholder: 'AAA0A00',
    accessor: 'plate',
  },
  {
    label: 'Status do Atendimento',
    type: 'text',
    placeholder: 'Status',
    accessor: 'scheduleStatus',
  },
  {
    label: 'Motivo',
    type: 'text',
    placeholder: 'Motivo',
    accessor: 'reason',
  },
  {
    label: 'Data de Agendamento',
    type: 'date',
    placeholder: 'Data',
    accessor: 'scheduleDay',
  },
  {
    label: 'Data de Previsão',
    type: 'date',
    placeholder: 'Data',
    accessor: 'previsionDate',
  },
  {
    label: 'Data de Saída',
    type: 'date',
    placeholder: 'Data',
    accessor: 'exitDate',
  },
  {
    label: 'Situação do Veículo',
    type: 'text',
    placeholder: 'Situação',
    accessor: 'status',
  },
];

export const ModelTable = [
  {
    title: 'Placas',
    type: 'text',
    accessor: 'plate',
  },
  {
    title: 'Marca',
    type: 'text',
    accessor: 'brand',
  },
  {
    title: 'Modelo',
    type: 'text',
    accessor: 'model',
  },
  {
    title: 'Ano Modelo',
    type: 'year',
    accessor: 'year',
  },
  {
    title: 'Fabricação',
    type: 'text',
    accessor: 'born',
  },
  {
    title: 'Cor',
    type: 'text',
    accessor: 'color',
  },
  {
    title: 'Região',
    type: 'text',
    accessor: 'region',
  },
  {
    title: 'Km Atual',
    type: 'text',
    accessor: 'km',
  },
];

export const ModelFilters = [
  {
    label: 'Placas',
    type: 'plate',
    placeholder: 'AAA0A00',
    accessor: 'plate',
  },
  {
    label: 'Marca',
    type: 'text',
    placeholder: 'Volks',
    accessor: 'brand',
  },
  {
    label: 'Modelo',
    type: 'text',
    placeholder: 'GOL',
    accessor: 'model',
  },
  {
    label: 'Ano Modelo',
    type: 'year',
    placeholder: '2020',
    accessor: 'year',
  },
  {
    label: 'Fabricação',
    type: 'date',
    placeholder: '2021',
    accessor: 'born',
  },
  {
    label: 'Cor',
    type: 'text',
    placeholder: 'Prata',
    accessor: 'color',
  },
  {
    label: 'Região',
    type: 'text',
    placeholder: 'Sudeste',
    accessor: 'region',
  },
  {
    label: 'Km Atual',
    type: 'distance',
    placeholder: '10.000',
    accessor: 'km',
  },
];

export const models: TableData = {
  itens: [],
  pageCount: 100,
  rows: 100,
};

for (let index = 0; index < 50; index++) {
  models.itens.push({
    plate: ['BRA2E19', 'DFD7DF4', 'SDF7G74', 'HUT5A23', 'TRA6G87'][
      Math.floor(Math.random() * 5)
    ],
    brand: 'FACCHINI',
    model: 'Semi Reboque',
    year: '2021',
    born: '2022',
    color: 'Branco',
    region: 'Sudeste',
    km: '4.000',
  });
}

export const testSelectLabels = [
  { label: 'Sudeste', value: 'SE' },
  { label: 'Sul', value: 'SO' },
  { label: 'Nordeste', value: 'NE' },
  { label: 'Norte', value: 'NO' },
  { label: 'Centro-Oeste', value: 'CW' },
];
//#endregion

export const mockedWidgets = [
  {
    id: '0',
    name: 'Regiões do brasil - distribuição da frota pelo país',
    size: '100%',
  },
  { id: '1', name: 'Total de atendimentos realizados', size: '100%' },
  { id: '2', name: 'Serviços realizados', size: '100%' },
  { id: '3', name: 'Entrada e saída de manutenções', size: '100%' },
  {
    id: '4',
    name: 'Distribuição de veículos por idade e km',
    size: '100%',
  },
  {
    id: '5',
    name: 'Distribuição de frotas por modelos',
    size: '100%',
  },
];

export const skeletonLabels = ['', '', '', '', '', '', '', '', '', '', '', ''];

export const skeletonLineData: ChartData[] = [
  {
    label: '',
    borderColor: colors.grey['500'],
    values: skeletonLabels.map(() => Math.floor(Math.random() * 100)),
  },
];

export const skeletonBarData: ChartData[] = [
  {
    label: '',
    fill: {
      type: 'start',
      color: colors.grey['500'],
    },
    values: skeletonLabels.map(() => Math.floor(Math.random() * 100)),
  },
];
