import { addYears, parse, format } from 'date-fns';

export const isValidCPF = (document: string): boolean => {
  if (typeof document !== 'string') {
    return false;
  }

  // eslint-disable-next-line no-param-reassign
  document = document.replace(/[^\d]+/g, '');

  if (document.length !== 11 || !!document.match(/(\d)\1{10}/)) {
    return false;
  }

  const cpf = document.split('').map(el => +el);

  const rest = (count: number) =>
    ((cpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;

  return rest(10) === cpf[9] && rest(11) === cpf[10];
};

const initialDateValidation = (data: Date) => {
  if (!data) {
    return false;
  }

  const dataConvertida = parse(
    format(data, 'yyyy-MM-dd'),
    'yyyy-MM-dd',
    new Date(),
  );

  return dataConvertida;
};

export const validateYoungDriver = (data: Date) => {
  const convertedDate = initialDateValidation(data);

  if (convertedDate) {
    if (
      new Date(convertedDate).getTime() >= addYears(new Date(), -18).getTime()
    ) {
      return false;
    }
    return true;
  }
  return false;
};

export const validateOldDriver = (data: Date) => {
  const convertedDate = initialDateValidation(data);

  if (convertedDate) {
    if (new Date().getFullYear() - convertedDate.getFullYear() > 120) {
      return false;
    }
    return true;
  }
  return false;
};
