import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import {
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Center,
  Box,
  Icon,
} from '@chakra-ui/react';
import {
  api,
  clearGlobalData,
  clearUser,
  setPage,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';

export const HeaderMenu = () => {
  const dispatch = useAppDispatch();
  const { name, abbreviation } = useAppSelector(state => state.authSlicer.user);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        aria-label={`menu sair ${name} ${abbreviation}`}
        data-cy="user"
        py={2}
        transition="all 0.3s"
        _focus={{ boxShadow: 'none', border: '1px solid white' }}
        borderRadius="8px"
      >
        <HStack mr="32px" ml="32px">
          <VStack
            mr="16px"
            display={{ base: 'none', xl: 'flex' }}
            alignItems="flex-start"
            spacing="1px"
          >
            <Text isTruncated color="white" fontSize="sm">
              {name}
            </Text>
            <Text isTruncated fontSize="xs" color="grey.300">
              {abbreviation}
            </Text>
          </VStack>
          <Center
            display={{ base: 'none', xl: 'flex' }}
            border="0.5px solid white"
            w="20px"
            h="20px"
            borderRadius="16px"
          >
            <Icon as={FiChevronDown} color="white" />
          </Center>
        </HStack>
        <Box display={{ base: 'flex', xl: 'none' }}>
          <AiOutlineUser size={18} />
        </Box>
      </MenuButton>
      <MenuList
        zIndex="dropdown"
        bg="mutable.100"
        sx={{
          '&::-webkit-scrollbar': {
            borderRadius: '8px',
            backgroundColor: 'ocean.100',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'ocean.200',
          },
        }}
        maxHeight="90vh"
        overflowY="auto"
      >
        <HStack>
          <MenuItem
            onClick={() => {
              dispatch(setPage({ page: null }));
              dispatch(clearUser());
              dispatch(clearGlobalData());
              dispatch(api.util.resetApiState());
            }}
            bg="mutable.100"
          >
            Sair
          </MenuItem>
        </HStack>
      </MenuList>
    </Menu>
  );
};
