import { useCallback } from 'react';

export function useDateSelect(
  setDateRange: ((param: DateProps[]) => void) | undefined,
) {
  const onDateRangeChange = useCallback(
    (node: { startDate: Date; endDate: Date; key: string }[]) => {
      if (setDateRange && node) setDateRange(node);
    },
    [],
  );

  return { onDateRangeChange };
}
