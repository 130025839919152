import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Image,
  Button,
  Flex,
  Container,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  setUser,
  useLoginMutation,
  useAppDispatch,
  useResetPasswordMutation,
  setDashBoardCards,
  setJustLoggedIn,
  useAppSelector,
} from '@spartalabs/pdc-core';
import { LoginTruck } from 'assets';
import logo from 'assets/svg/logo-dois.svg';
import SimpleInput from 'components/SimpleInput';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_LOGIN } from 'utils/constants';
import { isValidCPF } from 'utils/validations';
import * as z from 'zod';
import { FgtPwModal } from './components/forgotPwModal';
import PwdRequirements from './components/PwdRequirements';

const loginSchema = z.object({
  username: z
    .string()
    .min(14, { message: 'CPF não pode ser vazio' })
    .nonempty('CPF não pode ser vazio')
    .refine(value => !value.includes('_'), 'CPF vazio ou incompleto')
    .refine(value => isValidCPF(value), 'CPF inválido'),

  password: z.string().nonempty('Senha não pode ser vazia'),
});

const pwdSchema = z
  .object({
    newPwd: z
      .string()
      .min(8, 'Senha precisa ter no mínimo 8 dígitos')
      .nonempty('Senha não pode ser vazia')
      .refine(
        value => /[a-z]/.test(value),
        'Senha precisa ter pelo menos 1 letra minúscula',
      )
      .refine(
        value => /[A-Z]/.test(value),
        'Senha precisa ter pelo menos 1 letra maiúscula',
      )
      .refine(
        value => /[0-9]/.test(value),
        'Senha precisa ter pelo menos um número',
      )
      .refine(
        value => /\W/.test(value),
        'Senha precisa ter pelo menos 1 carácter especial',
      ),
    confirmNewPwd: z.string(),
  })
  .refine(data => data.confirmNewPwd === data.newPwd, {
    message: 'Senhas não são iguais',
    path: ['confirmNewPwd'],
  });

const Login: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [login, { isLoading: isLoadingLogin, isError }] = useLoginMutation();
  const [resetPwd, { isLoading: isLoadingResetPwd }] =
    useResetPasswordMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const page = useAppSelector(state => state.pagesSlicer.lastPage);

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_LOGIN,
  });

  const [currentUsername, setCurrentUsername] = useState<string | undefined>();
  const [currentPassword, setCurrentPassword] = useState<string | undefined>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<{
    username?: string;
    password?: string;
    newPwd?: string;
    confirmNewPwd?: string;
  }>({
    resolver: zodResolver(currentUsername ? pwdSchema : loginSchema),
  });

  const newPwd = watch('newPwd');
  const handleFill = useCallback((value?: string) => {
    setValue('username', value);
  }, []);

  const onSubmit = useCallback(
    async (data: {
      username?: string;
      password?: string;
      newPwd?: string;
      confirmNewPwd?: string;
    }) => {
      try {
        let user = {} as User;
        if (currentUsername && currentPassword) {
          user = await resetPwd({
            username: currentUsername || '',
            pwd: currentPassword || '',
            newPwd: data.newPwd || '',
          }).unwrap();
        } else {
          user = await login({
            username: data.username || '',
            password: data.password || '',
          }).unwrap();
        }

        if (user && !user.success)
          throw Error((user.msnErrors && user.msnErrors[0]) || '');

        if (user.provisionalPassword === 'S' || user.firstAccess === 'S')
          throw Error('new pwd');

        dispatch(setUser({ user }));
        dispatch(setDashBoardCards(user.dashboardConfig));

        //User just logged in
        dispatch(setJustLoggedIn(true));

        analyticsEvent(
          `${user.currentGroup?.currentGroupName} | Efetuou o login`,
        );

        navigate(page || '/inicio');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.message === 'new pwd') {
          setCurrentUsername(data.username);
          setCurrentPassword(data.password);
        }
      }
    },
    [currentUsername, currentPassword, page],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box flex={1} backgroundColor="white">
        <FgtPwModal isOpen={isOpen} onClose={onClose} />
        <Flex minH="100vh">
          <Box
            display={{ base: 'none', md: 'inline' }}
            w="50%"
            maxW="912px"
            pl="79px"
            pr="95px"
            pt="100px"
            backgroundSize="cover"
            backgroundImage={`url(${LoginTruck})`}
          >
            <Text fontWeight="700" fontSize="40px">
              Soluções que facilitam a gestão do negócio.
            </Text>
            <Text mt="18px">
              Os planos permitem que os clientes estejam no controle de suas
              frotas e que seus negócios contem ainda mais com tecnologia e
              inovação.
            </Text>
          </Box>
          <Flex
            pt={currentUsername && currentPassword ? '0px' : '150px'}
            align={currentUsername && currentPassword ? 'center' : 'start'}
            flex={1}
          >
            <Container maxW="430px">
              <Flex justifyContent={{ base: 'center', md: 'start' }}>
                <Image alt="" src={logo} mb="32px" h="30%" w="30%" />
              </Flex>

              {currentUsername && currentPassword ? (
                <>
                  <Text
                    mb="32px"
                    fontWeight="500"
                    fontSize="22px"
                    color="black"
                  >
                    Crie uma nova senha
                  </Text>

                  <SimpleInput
                    label="Nova senha"
                    {...register('newPwd')}
                    id="newPwd"
                    error={errors?.newPwd?.message?.toString()}
                    bg="transparent"
                    placeholder="Informe sua nova senha"
                    type="password"
                    mb="18px"
                  />

                  <SimpleInput
                    label="Confirme nova senha"
                    {...register('confirmNewPwd')}
                    id="confirmNewPwd"
                    error={errors?.confirmNewPwd?.message?.toString()}
                    bg="transparent"
                    placeholder="Confirme sua nova senha"
                    type="password"
                    mb="18px"
                  />

                  <PwdRequirements password={newPwd} mb="18px" />
                </>
              ) : (
                <>
                  <Text fontWeight="500" fontSize="22px" color="black">
                    Bem vindo
                  </Text>

                  <Text fontSize="sm" color="text.300">
                    Insira as informações abaixo para logar
                  </Text>

                  <SimpleInput
                    id="username"
                    error={errors?.username?.message?.toString()}
                    bg="transparent"
                    mt="18px"
                    mb="18px"
                    placeholder="Digite seu CPF"
                    autoComplete="on"
                    handleFill={handleFill}
                    value={watch('username')}
                    type="cpf"
                  />

                  <SimpleInput
                    {...register('password')}
                    id="password"
                    error={errors?.password?.message?.toString()}
                    bg="transparent"
                    current-password="true"
                    autoComplete="on"
                    placeholder="Digite sua senha"
                    type="password"
                  />

                  {isError && (
                    <Text mt={2} color="red.200">
                      Login ou senha inválidos
                    </Text>
                  )}

                  <Flex mt="18px" mb="36px">
                    <Button
                      textColor="ocean.200"
                      variant="link"
                      _hover={{ opacity: 0.5 }}
                      onClick={onOpen}
                      as="button"
                      marginInlineStart="auto"
                    >
                      Esqueci a senha
                    </Button>
                  </Flex>
                </>
              )}

              <Button
                w="100%"
                type="submit"
                isLoading={isLoadingLogin || isLoadingResetPwd}
              >
                Entrar
              </Button>

              <Flex mb="24px" mt="18px" w="100%" justifyContent="center">
                <Text color="text.300" fontSize="xs">
                  Não tem acesso?
                </Text>
                <Link
                  textDecor="underline"
                  href="https://forms.office.com/pages/responsepage.aspx?id=Eck14AqgPE-H3gsZueFVTpsOrPtl-pNHjw6cfuWPDtlUREhWRDFWSzlVMElIODZBQUNFVFQyWVVFQy4u"
                  ml="4px"
                  fontSize="xs"
                  color="ocean.200"
                  onClick={() => analyticsEvent('Solicitou novo acesso')}
                >
                  Clique e faça a solicitação
                </Link>
              </Flex>
            </Container>
          </Flex>
        </Flex>
      </Box>
    </form>
  );
};

export default Login;
