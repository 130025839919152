import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { dropContent } from '../NavButtons';

interface CollapsedOptions {
  dropOptions?: dropContent[];
}

export const CollapsedOptions: React.FC<CollapsedOptions> = ({
  dropOptions,
}) => {
  return (
    <MenuList
      pb="0px"
      ml="70px"
      mt="-70px"
      bg="mutable.100"
      borderColor="ocean.400"
      flexDir="column"
    >
      <>
        {dropOptions?.map(drop => {
          return (
            <Accordion borderColor="transparent" allowToggle key={drop.name}>
              <AccordionItem>
                {!drop.insideDrops ? (
                  <NavLink to={drop.path}>
                    <MenuItem
                      bg="mutable.100"
                      as={Button}
                      key={drop.name}
                      isDisabled={false}
                      _hover={{ bg: 'mutable.100' }}
                      _active={{ bg: 'mutable.100' }}
                    >
                      {drop.name}
                    </MenuItem>
                  </NavLink>
                ) : (
                  <AccordionButton
                    bg="mutable.100"
                    as={Button}
                    key={drop.name}
                    rightIcon={<AccordionIcon />}
                    _hover={{ bg: 'mutable.100' }}
                    _active={{ bg: 'mutable.100' }}
                  >
                    {drop.name}
                  </AccordionButton>
                )}

                <AccordionPanel p="0px" m="0">
                  {drop.insideDrops?.map(inside => {
                    return (
                      <NavLink to={inside.path} key={inside.name}>
                        <MenuItem
                          _hover={{ bg: 'mutable.200' }}
                          borderRadius="0px"
                          bg="mutable.200"
                          as={Button}
                        >
                          {inside.name}
                        </MenuItem>
                      </NavLink>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })}
      </>
    </MenuList>
  );
};
