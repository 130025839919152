import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Image,
} from '@chakra-ui/react';
import {
  setEnterprise,
  useAppDispatch,
  useSetEnterpriseMutation,
  useSetLogoMutation,
  setLogo,
} from '@spartalabs/pdc-core';
import { useAnalyticsEvent, useCustomToast } from 'hooks';
import { CUSTOMIZE_LABEL } from 'utils/constants';
import trashSvg from '../../../../../assets/svg/trash.svg';

interface CancelModalProps {
  isOpen: boolean;
  onClose: (isCancel?: boolean) => void;
  userImage: string | null;
  userTitle: string | null;
}

interface ImageProps {
  file: File | null;
  name: string;
  path: string | null;
}

export const CustomModal = ({
  isOpen,
  onClose,
  userImage,
  userTitle,
}: CancelModalProps) => {
  const dispatch = useAppDispatch();

  const [enterprise, { isLoading: isLoadingLogin }] =
    useSetEnterpriseMutation();
  const [uploadMutation, { isLoading }] = useSetLogoMutation();

  const [images, setImages] = useState<ImageProps>({
    file: null,
    name: 'Logo',
    path: userImage,
  });

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: CUSTOMIZE_LABEL,
  });

  const [title, setTitle] = useState<string | null>(userTitle);

  const input = useRef<HTMLInputElement>(null);

  const { handleToast } = useCustomToast();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const newPreviewImages = Array.from(selectedFiles).map(file => {
        const reader = new FileReader();

        return new Promise<string>((resolve, reject) => {
          reader.onload = function (event) {
            if (event.target?.result) {
              resolve(event.target.result.toString());
            } else {
              reject(new Error('Failed to read the file.'));
            }
          };

          reader.onerror = function (event) {
            reject(
              event.target?.error ?? new Error('Failed to read the file.'),
            );
          };

          reader.readAsDataURL(file as Blob);
        });
      });

      Promise.all(newPreviewImages)
        .then(result => {
          const names = Array.from(selectedFiles).map(
            (file: File) => file.name,
          );
          setImages({
            file: selectedFiles[0],
            name: names[0],
            path: result[0],
          });
        })
        .catch(() =>
          handleToast({
            step: 4,
            action: 'selecionar sua logo',
          }),
        );
    }
  };

  const handleImage = useCallback(async () => {
    if (userImage === images.path) return false;
    try {
      if (images.file) {
        const formData = new FormData();

        formData.append('arquivo', images.file);

        await uploadMutation(formData).unwrap();
      } else {
        await uploadMutation(null).unwrap();
      }

      dispatch(
        setLogo({
          logo: images.path,
        }),
      );
      analyticsEvent('Trocou a imagem da companhia');
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleToast({
        step: 4,
        action: 'trocar a logo',
      });
      return false;
    }
  }, [userImage, images]);

  const handleTitle = useCallback(async () => {
    if (userTitle === title) return false;
    try {
      await enterprise(title || '').unwrap();

      dispatch(
        setEnterprise({
          title: title || '',
        }),
      );
      analyticsEvent('Trocou o nome da companhia');
      return true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleToast({
        step: 4,
        action: 'trocar o nome da sua empresa',
      });
      return false;
    }
  }, [userTitle, title]);

  const handleSubmit = useCallback(async () => {
    let titleSuccess = false;
    let imageeSuccess = false;

    await Promise.allSettled([handleTitle(), handleImage()]).then(values => {
      titleSuccess = values[0].status === 'fulfilled' ? values[0].value : false;
      imageeSuccess =
        values[1].status === 'fulfilled' ? values[1].value : false;
    });

    if (titleSuccess || imageeSuccess)
      handleToast({
        step: 5,
        action:
          titleSuccess && imageeSuccess
            ? 'Suas informações foram alteradas!'
            : titleSuccess
            ? 'O nome da companhia foi alterado!'
            : 'Sua logo foi alterada!',
      });

    onClose();
  }, [dispatch, images, title, userTitle, userImage]);

  useEffect(() => {
    if (input.current && !images.file) input.current.value = '';
  }, [images]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent w={{ base: '340px', md: '468px' }}>
        <ModalBody mt="40px">
          <Box w="full" display="flex" flexDir="column">
            <Text id="label" color="text.100" fontSize="sm">
              Adicione o nome da companhia
            </Text>
            <Input
              aria-labelledby="label"
              data-cy="title-input"
              maxLength={55}
              onChange={event => setTitle(event.target.value)}
              value={title || ''}
              border="1px solid"
              borderColor="grey.600"
            />
          </Box>

          <ModalCloseButton color="black" />

          <Box mt="40px" display="flex" flexDir="column">
            <Text color="text.100" fontSize="sm">
              Ao anexar imagem da marca, o arquivo teve ser em png ou jpeg com o
              máximo de 5mb
            </Text>

            <Flex
              mt="8px"
              w="280px"
              align="center"
              justify="space-between"
              borderColor="text.100"
              borderStyle="dashed"
              borderWidth="1px"
              rounded="md"
              shadow="sm"
              role="group"
              py="15px"
              px="24px"
              h="54px"
              transition="all 150ms ease-in-out"
              _hover={{
                shadow: 'md',
              }}
              position="relative"
            >
              <Text fontSize="12px" color="text.100">
                Faça o upload da marca aqui
              </Text>
              <Input
                data-cy="upload-input"
                ref={input}
                cursor="pointer"
                type="file"
                h="100%"
                width="100%"
                position="absolute"
                multiple
                top="0"
                left="0"
                bottom="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                border="3px solid red"
                onChange={handleImageChange}
              />
              <Flex bg="grey.200" py="8px" px="10px" rounded="md">
                <Text fontSize="14px" fontWeight="normal" color="black">
                  Upload
                </Text>
              </Flex>
            </Flex>

            {images.path && (
              <Flex mt="18px" w="100%" align="center">
                <Box>
                  <Image
                    width="32px"
                    height="32px"
                    rounded="md"
                    src={images?.path}
                  />
                </Box>
                <Text noOfLines={1} w="120px" ml="20px" color="black">
                  {images?.name}
                </Text>
                <Button
                  data-cy="remove-image"
                  onClick={() =>
                    setImages({ name: '', path: null, file: null })
                  }
                  bg="transparent"
                  p="0"
                  _hover={{ bg: 'transparent' }}
                >
                  <Image alt="remover logo" src={trashSvg} />
                </Button>
              </Flex>
            )}
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button variant="outline" mr={3} onClick={() => onClose()}>
            Voltar
          </Button>
          <Button
            isLoading={isLoadingLogin || isLoading}
            onClick={handleSubmit}
          >
            Finalizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
