import React, { useMemo, useState } from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { FiClock } from 'react-icons/fi';
import { HiOutlineChevronRight, HiOutlineExclamation } from 'react-icons/hi';
import { MdPlayCircleOutline, MdSend } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Text,
  Avatar,
  Box,
  Flex,
  Icon,
  PopoverBody,
  PopoverHeader,
  VStack,
  Center,
  Skeleton,
} from '@chakra-ui/react';
import { useAppSelector, useGetCategoriesQuery } from '@spartalabs/pdc-core';
import { HelpAvatar } from 'assets';
import { Card } from 'components/Cards/Card';
import SimpleInput from 'components/SimpleInput';

interface HomeTabProps {
  onClick: () => void;
  onClose: () => void;
  setIndex: (number: number) => void;
  pageName: string;
  setCategoryId: (id: string | null) => void;
}

const HomeTab: React.FC<HomeTabProps> = ({
  onClick,
  onClose,
  pageName,
  setIndex,
  setCategoryId,
}) => {
  const [search, setSearch] = useState<string>('');

  const {
    data: categories,
    isLoading: loadingCat,
    isError,
  } = useGetCategoriesQuery();

  const filteredCats = useMemo(() => {
    if (search.length >= 2)
      return (
        categories
          ?.filter(category =>
            category.title.toLowerCase().includes(search.toLowerCase()),
          )
          .slice(0, 3) || []
      );
    else return categories?.slice(0, 3) || [];
  }, [categories, search]);

  const username = useAppSelector(state => state.authSlicer.user.name);
  return (
    <>
      <PopoverHeader
        borderTopRadius="20px"
        bgGradient="linear(to-l, mutable.300, mutable.400,mutable.500)"
        textColor="black"
        pl="36px"
      >
        <Text mt="60px" fontSize="32px">
          Olá, {username?.split(' ')[0]}!
        </Text>
        <Text fontSize="16px">Como podemos ajudar?</Text>
      </PopoverHeader>

      <Box
        position="absolute"
        bgGradient="linear(to-l, mutable.300, mutable.400,mutable.500)"
        h="76px"
        w="100% "
        top={140}
        zIndex={1}
      />
      <PopoverBody
        maxHeight="452px"
        overflow="scroll"
        mt="14px"
        bg="background"
        sx={{
          '&::-webkit-scrollbar': {
            backgroundColor: 'grey.400',
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.500',
            borderRadius: '8px',
          },
        }}
      >
        <Card zIndex={2} position="relative">
          <Text textColor="black" fontWeight={600}>
            Nos envie uma mensagem
          </Text>
          <Text mt="-4px" textColor="text.100" fontSize="14px">
            Nós respondemos em alguns instantes
          </Text>
          <Flex alignItems="center" mt="16px">
            <Avatar w="64px" h="64px" src={HelpAvatar} />
            <Box ml="12px">
              <Text textColor="text.300" fontSize="14px">
                Horário de atendimento
              </Text>
              <Flex alignItems="center">
                <Icon color="mutable.100" as={FiClock} w="16px" h="16px" />
                <Text ml="4px" textColor="text.100" fontSize="14px">
                  08h às 17:48h
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Button
            as={NavLink}
            target="_blank"
            to="https://api.whatsapp.com/send?phone=5511978379385&text=Olá%20estou%20precisando%20de%20ajuda%20com%20o%20portal%20do%20cliente!"
            rightIcon={<MdSend />}
            w="172px"
            mt="20px"
          >
            Enviar mensagem
          </Button>
        </Card>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderRadius="10px"
          px="20px"
          py="18px"
          mt="20px"
          bgGradient="linear(to-l, mutable.300, mutable.400,  mutable.500)"
          onClick={() => {
            onClose();
            onClick();
          }}
        >
          <Box>
            <Text fontWeight={600} size="14px">
              {pageName}
            </Text>
            <Text size="14px">Iniciar tour guiado</Text>
          </Box>
          <Icon as={MdPlayCircleOutline} w="26px" h="26px" />
        </Flex>

        <Card mb="20px" bg="background" justifyContent="top" mt="20px">
          <SimpleInput
            placeholder="Buscar por ajuda"
            _placeholder={{ textColor: 'text.100', fontWeight: 500 }}
            type="search-ghost"
            onChange={e => setSearch(e.target.value)}
            value={search}
          />

          {loadingCat ? (
            [0, 1, 2, 3].map(fakeNatty => (
              <Skeleton mt="12px" key={fakeNatty} minW={0} h="20px" w="100%">
                <></>
              </Skeleton>
            ))
          ) : isError ? (
            <Center mt="20px" flexDir="column">
              <Icon
                borderRadius="100px"
                padding="4px"
                bg="grey.200"
                w="40px"
                h="40px"
                as={HiOutlineExclamation}
                color="grey.600"
              />
              <Text fontWeight="medium" color="text.100" fontSize="14px">
                Algo deu errado
              </Text>
              <Text color="text.300" fontSize="12px">
                Recarregue e tente novamente
              </Text>
            </Center>
          ) : filteredCats?.length > 0 ? (
            <VStack gap="12px" mt="16px" alignItems="center">
              {filteredCats?.map(category => (
                <Flex
                  onClick={() => {
                    setCategoryId(category.id);
                    setIndex(1);
                  }}
                  key={category.id}
                  w="94%"
                  justifyContent="space-between"
                >
                  <Text fontSize="14px" textColor="black">
                    {category.title}
                  </Text>
                  <Icon
                    color="black"
                    as={HiOutlineChevronRight}
                    h="12px"
                    w="12PX"
                    alignSelf="center"
                  />
                </Flex>
              ))}
            </VStack>
          ) : (
            <Center mt="20px" flexDir="column">
              <Icon
                borderRadius="100px"
                padding="4px"
                bg="grey.200"
                w="40px"
                h="40px"
                as={AiOutlineFileSearch}
                color="grey.600"
              />
              <Text
                mt="16px"
                fontWeight="medium"
                color="text.100"
                fontSize="14px"
              >
                Não foram encontradas informações
              </Text>
            </Center>
          )}
        </Card>
      </PopoverBody>
    </>
  );
};

export default HomeTab;
