import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { setPage, useAppDispatch, useAppSelector } from '@spartalabs/pdc-core';
import { GA4_KEY } from 'utils/constants';
import { urlFormatter } from 'utils/formaters';
import { isAllowed, isProd, shouldRecord } from 'utils/functions';
import { useCustomToast } from './useError';

const usePageTracking = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector(state => state.pagesSlicer.lastPage);

  const notification = useAppSelector(
    state => state.authSlicer.user.notification,
  );

  const { pathname } = useLocation();

  const { handleToast } = useCustomToast();

  useEffect(() => {
    if (notification?.length > 0) {
      notification.map((item, index) => {
        item?.plate &&
          handleToast({
            step: 7,
            action: item.plate,
            isLast: index === notification.length - 1,
          });
      });
    }

    if (!page && pathname === '/login') {
      dispatch(setPage({ page: '/inicio' }));
    } else {
      if (shouldRecord(pathname) && page) dispatch(setPage({ page: pathname }));
    }

    if (isAllowed() && !ReactGA.isInitialized) ReactGA.initialize(GA4_KEY);

    if (isProd() && isAllowed())
      ReactGA.send({
        hitType: 'pageview',
        page: pathname,
        title: urlFormatter(pathname),
      });
  }, [pathname, notification]);
};

export default usePageTracking;
