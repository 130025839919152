import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForgotPasswordMutation } from '@spartalabs/pdc-core';
import SimpleInput from 'components/SimpleInput';
import { useAnalyticsEvent, useCustomToast } from 'hooks';
import { GA_LOGIN } from 'utils/constants';
import * as z from 'zod';

interface FgtPwModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  email: string;
  cpf: string;
  birthdate: string;
}

const schema = z.object({
  email: z
    .string()
    .email('E-mail inválido')
    .nonempty('E-mail não pode ser vazio'),
  cpf: z
    .string()
    .min(14, { message: 'CPF inválido' })
    .nonempty('CPF não pode ser vazio'),
  birthdate: z
    .string()
    .min(10, 'Data inválida')
    .nonempty('Data não pode ser vazia'),
});

export const FgtPwModal = ({ isOpen, onClose }: FgtPwModalProps) => {
  const { handleToast } = useCustomToast();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_LOGIN,
  });

  const [fgtPwd, { isLoading }] = useForgotPasswordMutation();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ resolver: zodResolver(schema) });

  const handleFill = useCallback((value?: string) => {
    setValue('cpf', value || '');
  }, []);

  const onSubmit = useCallback(async (data: FormValues) => {
    try {
      const response = await fgtPwd(data).unwrap();

      if (!response.success) throw Error(response.message);

      handleToast({
        step: 5,
        action: 'E-mail enviado!',
        successText: 'Foi enviado um e-mail para recuperação de senha.',
      });

      analyticsEvent('Solicitou nova senha');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleToast({
        step: 4,
        action: 'enviar o email',
        helpText: `Algo deu errado. ${error.message}`,
      });
    } finally {
      reset();
      onClose();
    }
  }, []);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={
        isLoading
          ? () => null
          : () => {
              reset();
              onClose();
            }
      }
    >
      <ModalOverlay />
      <ModalContent py="36px " px="0px" bg="white">
        <ModalHeader
          p="0px"
          fontSize="xl"
          fontWeight={500}
          color="black"
          alignSelf="center"
        >
          Esqueci a senha
        </ModalHeader>
        <ModalCloseButton bg="grey.200" borderRadius="36px" color="black" />
        <ModalBody py="0px " px="40px" textAlign="center">
          <VStack w="100%" mb="16px" spacing="16px">
            <Text mt="8px" mb="16px" color="grey.600">
              a redefinição de senha chegará no seu e-mail
            </Text>
            <SimpleInput
              {...register('email')}
              id="email"
              error={errors?.email?.message?.toString()}
              label="E-mail"
              placeholder="Digite seu e-mail"
              bg="transparent"
            />
            <SimpleInput
              {...register('cpf')}
              id="cpf"
              error={errors?.cpf?.message?.toString()}
              label="CPF"
              type="cpf"
              placeholder="Digite seu CPF"
              bg="transparent"
              handleFill={handleFill}
            />
            <SimpleInput
              {...register('birthdate')}
              id="birthdate"
              error={errors?.birthdate?.message?.toString()}
              label="Data de nascimento"
              type="date"
              placeholder="Digite sua data de nascimento"
              bg="transparent"
            />
          </VStack>
        </ModalBody>

        <ModalFooter px="40px" pb="0px">
          <Button
            isLoading={isLoading}
            width="100%"
            onClick={handleSubmit(onSubmit)}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
