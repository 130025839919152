import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Divider,
  Flex,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import {
  api,
  setDivisions,
  useAppDispatch,
  useAppSelector,
  useSetBaseMutation,
} from '@spartalabs/pdc-core';
import { LoadingModal } from 'components/LoadingModal';
import SimpleInput from 'components/SimpleInput';
import { useCustomToast } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';

export const BaseFilters: React.FC = () => {
  const dispatch = useAppDispatch();

  const { groups, currentDivisionId, currentGroupId } = useAppSelector(
    state => state.authSlicer.user,
  );

  const { handleToast } = useCustomToast();

  const [setBase, { isLoading }] = useSetBaseMutation();

  const [bases, setBases] = useState<string[] | undefined>(
    currentDivisionId ? currentDivisionId.split(',') : undefined,
  );
  const [showMore, setShowMore] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const [group, setGroup] = useState<string | undefined>(
    currentGroupId ? currentGroupId : undefined,
  );

  const { debouncedValue } = useDebounce(
    JSON.stringify({ group, bases }),
    1000,
  );

  const handleSetBase = async (debouncedValue: {
    group: string;
    bases: string[];
  }) => {
    if (isLoading) return;

    try {
      const base = !bases?.length
        ? [
            groups
              ?.filter(group => group.groupId === debouncedValue.group)?.[0]
              .groupDivision.filter(
                division =>
                  division.divisionName.toLocaleLowerCase() === 'não atribuído',
              )[0]?.divisionId || '',
          ]
        : debouncedValue.bases;

      await setBase({
        group: debouncedValue.group || '',
        divisions: base ? base : [],
      });

      dispatch(api.util.resetApiState());

      dispatch(
        setDivisions({
          bases: base && base.length > 0 ? base.join(',') : '',
          group: debouncedValue.group || '',
        }),
      );
      setGroup(debouncedValue.group);

      setBases(base ? base : []);

      handleToast({
        step: 5,
        action: 'Base alterada com sucesso!',
      });
    } catch (error) {
      handleToast({
        step: 4,
        action: 'alterar as bases',
      });
    }
  };

  const handleSelect = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      groupId: string,
      divisionId: string,
    ) => {
      {
        if (group !== groupId) {
          setGroup(groupId);
          setBases([divisionId]);
        } else if (e.target.checked) {
          setBases(prev => (prev ? [...prev, divisionId] : [divisionId]));
        } else {
          setBases(prev =>
            prev ? prev.filter(value => value !== divisionId) : undefined,
          );
        }
      }
    },
    [group],
  );

  const { uniqueBases, multipleBases } = useMemo(() => {
    const uniqueBases: Group[] = [];
    const multipleBases: Group[] = [];

    groups?.map(group => {
      if (group.groupDivision.length === 1) {
        if (search.length > 1) {
          if (group.groupName.toLowerCase().includes(search.toLowerCase()))
            return uniqueBases.push(group);
        } else uniqueBases.push(group);
      } else {
        if (search.length > 1) {
          const filteredDivisions = group.groupDivision.filter(filter =>
            filter.divisionName.toLowerCase().includes(search.toLowerCase()),
          );

          if (filteredDivisions.length > 0)
            return multipleBases.push({
              groupId: group.groupId,
              groupName: group.groupName,
              groupDivision: filteredDivisions,
            });
        } else multipleBases.push(group);
      }
    });

    return { uniqueBases, multipleBases };
  }, [search]);

  useEffect(() => {
    const sortedBases = bases?.sort((a, b) => Number(a) - Number(b));

    const sortedcurrentDivision = currentDivisionId
      ?.split(',')
      .sort((a, b) => Number(a) - Number(b));

    if (JSON.stringify(sortedBases) !== JSON.stringify(sortedcurrentDivision))
      handleSetBase(JSON.parse(debouncedValue));
  }, [debouncedValue]);

  return (
    <VStack alignItems="start">
      <LoadingModal isOpen={isLoading} />
      <Accordion defaultIndex={[0]} w="96%" allowToggle>
        <AccordionItem borderColor="transparent">
          <AccordionButton
            justifyContent="space-between"
            flex="1"
            fontWeight={500}
            textColor="black"
          >
            Bases
            <AccordionIcon color="black" />
          </AccordionButton>
          <AccordionPanel>
            <SimpleInput
              mt="-4px"
              fontSize="14px"
              placeholder="Digite a sua busca"
              type="search-ghost"
              mb="8px"
              onChange={e => setSearch(e.target.value)}
              _placeholder={{ color: 'text.100' }}
            />

            <Accordion
              mt="-12px"
              allowToggle
              borderColor="transparent"
              w="100%"
              defaultIndex={[...uniqueBases, ...multipleBases]?.findIndex(
                item => item.groupId === group,
              )}
            >
              {uniqueBases?.map(division => (
                <AccordionItem key={division.groupId}>
                  <AccordionButton
                    ml="-8px"
                    aria-label={`Mudar exibição para ${division.groupName}`}
                    justifyContent="space-between"
                  >
                    <Checkbox
                      fontWeight={500}
                      borderColor="grey.300"
                      color="text.100"
                      isChecked={division.groupId === group}
                      onChange={() => {
                        setGroup(division.groupId);
                        setBases([division.groupDivision[0].divisionId]);
                      }}
                      fontSize="14px"
                      noOfLines={1}
                      textAlign="start"
                    >
                      {division.groupName}
                    </Checkbox>
                  </AccordionButton>
                </AccordionItem>
              ))}
              {multipleBases?.length > 0 && uniqueBases?.length > 0 && (
                <Divider
                  borderWidth="0.5px"
                  borderColor="#D3D3D4"
                  orientation="horizontal"
                />
              )}

              {multipleBases?.map(division => (
                <AccordionItem key={division.groupId}>
                  <AccordionButton
                    pt="8px"
                    pl="0px"
                    aria-label={`Mudar exibição para ${division.groupName}`}
                    justifyContent="space-between"
                  >
                    <Text
                      noOfLines={1}
                      textAlign="start"
                      fontWeight={500}
                      color="text.100"
                      fontSize="14px"
                    >
                      {division.groupName}
                    </Text>

                    {search.length <= 1 && (
                      <AccordionIcon color="text.100" ml="8px" />
                    )}
                  </AccordionButton>

                  {search.length > 1 ? (
                    division.groupDivision.map(searchGroup => (
                      <Checkbox
                        ml="8px"
                        key={searchGroup.divisionId + 'search'}
                        fontWeight={500}
                        borderColor="grey.300"
                        color="text.100"
                        py="8px"
                        _first={{ pt: '0px' }}
                        w="full"
                        isChecked={bases?.includes(searchGroup.divisionId)}
                        onChange={e =>
                          handleSelect(
                            e,
                            division.groupId,
                            searchGroup.divisionId,
                          )
                        }
                        fontSize="14px"
                        noOfLines={1}
                        textAlign="start"
                      >
                        {searchGroup.divisionName}
                      </Checkbox>
                    ))
                  ) : (
                    <AccordionPanel bg="grey.100">
                      {division.groupDivision.length > 1 &&
                        division.groupDivision
                          .slice(
                            0,
                            showMore === division.groupId
                              ? division.groupDivision.length
                              : 9,
                          )
                          .map(item => (
                            <Checkbox
                              ml="-8px"
                              py="8px"
                              _first={{ pt: '0px' }}
                              _last={{ pb: '0px' }}
                              w="full"
                              key={item.divisionId}
                              aria-label={`Mudar exibição para ${item.divisionName}`}
                              fontWeight={500}
                              borderColor="grey.300"
                              textColor="text.100"
                              alignSelf="center"
                              fontSize="14px"
                              colorScheme="blue"
                              isChecked={bases?.includes(item.divisionId)}
                              onChange={e =>
                                handleSelect(
                                  e,
                                  division.groupId,
                                  item.divisionId,
                                )
                              }
                            >
                              {item.divisionName}
                            </Checkbox>
                          ))}

                      {division.groupDivision.length > 10 && (
                        <Flex
                          onClick={() =>
                            setShowMore(show =>
                              show === division.groupId
                                ? null
                                : division.groupId,
                            )
                          }
                          mt="4px"
                          justifyContent="flex-end"
                          alignItems="center"
                          ml="auto"
                        >
                          <Text cursor="pointer" mr="8px" color="blue.0">
                            mostrar mais
                          </Text>
                          <Icon
                            as={IoIosArrowDown}
                            h="14px"
                            w="14px"
                            mt="4px"
                            color="blue.0"
                            transition="all .5s ease"
                            transform={
                              showMore === division.groupId
                                ? 'rotate(-180deg)'
                                : 'rotate(-360deg)'
                            }
                          />
                        </Flex>
                      )}
                    </AccordionPanel>
                  )}

                  <Divider
                    borderWidth="0.5px"
                    borderColor="#D3D3D4"
                    orientation="horizontal"
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};
