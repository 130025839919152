import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import PWAPrompt from 'react-ios-pwa-prompt';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from '@spartalabs/pdc-core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LineController,
  BarController,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SideBar } from 'components';
import { GlobalDnd } from 'components/GlobalDnd';
import { ColorProvider } from 'hooks/useColor';
import { AppRoutes } from 'routes';
import { GA4_KEY } from 'utils/constants';
import { isAllowed } from 'utils/functions';
import packageInfo from '../package.json';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';

isAllowed() && ReactGA.initialize(GA4_KEY);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
);

ChartJS.defaults.set('plugins.datalabels', {
  color: 'transparent',
});

// eslint-disable-next-line no-console
console.log(
  `Olá, você está utilizando a versão ${packageInfo.version} do Portal do Cliente`,
);

root.render(
  <BrowserRouter>
    <PWAPrompt
      timesToShow={3}
      promptOnVisit={2}
      delay={3000}
      copyTitle="Fixar o Portal na Tela Inicial"
      copyBody="Este site possui funcionalidades de aplicativo. Adicione-o à sua tela inicial para utilizá-lo em tela cheia com mais praticidade"
      copyShareButtonLabel="1) Pressione o botão 'Compartilhar'"
      copyAddHomeButtonLabel="2) Pressione 'Adicionar à Tela de Início'"
      copyClosePrompt="Cancelar"
    />
    <AppProvider>
      <ColorProvider>
        <GlobalDnd>
          <SideBar>
            <AppRoutes />
          </SideBar>
        </GlobalDnd>
      </ColorProvider>
    </AppProvider>
  </BrowserRouter>,
);

serviceWorker.register();

reportWebVitals();
