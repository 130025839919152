import React from 'react';
import { BrazilSvg } from './BrazilSvg';
import { CWSvg } from './Regions/CWSvg';
import { NESvg } from './Regions/NESvg';
import { NOSvg } from './Regions/NOSvg';
import { SESvg } from './Regions/SESvg';
import { SOSvg } from './Regions/SOSvg';

interface MapsSvgProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapColor?: any;
  mapType?: string;
  mapW?: string;
  mapH?: string;
  fromBrazil?: boolean;
  isClickable?: boolean;
  setSelected?: (param: string) => void;
}

export const MapsSvg: React.FC<MapsSvgProps> = ({
  mapColor,
  mapH,
  mapType,
  mapW,
  fromBrazil,
  isClickable,
  setSelected,
}) => {
  switch (mapType?.toLocaleLowerCase()) {
    case 'brazil':
      return (
        <BrazilSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'br':
      return (
        <BrazilSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'se':
      return (
        <SESvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          fromBrazil={fromBrazil}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'so':
      return (
        <SOSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          fromBrazil={fromBrazil}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'ne':
      return (
        <NESvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          fromBrazil={fromBrazil}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'no':
      return (
        <NOSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          fromBrazil={fromBrazil}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    case 'cw':
      return (
        <CWSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          fromBrazil={fromBrazil}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );

    default:
      return (
        <BrazilSvg
          width={mapW || '150px'}
          height={mapH || '150px'}
          active={{ ...mapColor }}
          isClickable={isClickable}
          setSelected={setSelected}
        />
      );
  }
};
