import React, { useEffect, useState } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { Text, Icon, BoxProps, HStack, VStack } from '@chakra-ui/react';

interface PwdRequirementsProps extends BoxProps {
  password?: string;
}

const PwdRequirements: React.FC<PwdRequirementsProps> = ({
  password,
  ...rest
}) => {
  const [lowerLetteRequirement, setLowerLetteRequirement] = useState(false);
  const [upperLetterRequirement, setUpperLetterRequirement] = useState(false);
  const [digitsRequirement, setDigitsRequirement] = useState(false);
  const [numberRequirement, setNumberRequirement] = useState(false);
  const [specialCharRequirement, setSpecialCharspecialCharRequirement] =
    useState(false);

  useEffect(() => {
    if (password && password?.length > 7) {
      setDigitsRequirement(true);
    } else {
      setDigitsRequirement(false);
    }

    if (password && /[a-z]/g.test(password)) {
      setLowerLetteRequirement(true);
    } else {
      setLowerLetteRequirement(false);
    }

    if (password && /[A-Z]/g.test(password)) {
      setUpperLetterRequirement(true);
    } else {
      setUpperLetterRequirement(false);
    }

    if (password && /[\W]/g.test(password)) {
      setSpecialCharspecialCharRequirement(true);
    } else {
      setSpecialCharspecialCharRequirement(false);
    }

    if (password && /[\d]/g.test(password)) {
      setNumberRequirement(true);
    } else {
      setNumberRequirement(false);
    }
  }, [password]);

  return (
    <VStack {...rest} align="center" justify="center">
      <Text color="text.100" fontWeight={800} alignSelf="center">
        Não utilize senhas recentes
      </Text>
      <HStack w="100%" alignContent="center">
        <Icon
          as={lowerLetteRequirement ? AiFillCheckCircle : AiFillCloseCircle}
          color={lowerLetteRequirement ? 'green.600' : 'red.200'}
          fontSize="24px"
          alignSelf="center"
        />

        <Text
          color={lowerLetteRequirement ? 'green.600' : 'red.200'}
          alignSelf="center"
        >
          Pelo menos 1 letra minúscula
        </Text>
      </HStack>
      <HStack w="100%" alignContent="center">
        <Icon
          as={upperLetterRequirement ? AiFillCheckCircle : AiFillCloseCircle}
          color={upperLetterRequirement ? 'green.600' : 'red.200'}
          fontSize="24px"
          alignSelf="center"
        />

        <Text
          color={upperLetterRequirement ? 'green.600' : 'red.200'}
          alignSelf="center"
        >
          Pelo menos 1 letra maiúscula
        </Text>
      </HStack>
      <HStack w="100%" alignContent="center">
        <Icon
          as={digitsRequirement ? AiFillCheckCircle : AiFillCloseCircle}
          color={digitsRequirement ? 'green.600' : 'red.200'}
          fontSize="24px"
          alignSelf="center"
        />

        <Text
          color={digitsRequirement ? 'green.600' : 'red.200'}
          alignSelf="center"
        >
          Mínimo 8 caracteres
        </Text>
      </HStack>
      <HStack w="100%" alignContent="center">
        <Icon
          as={numberRequirement ? AiFillCheckCircle : AiFillCloseCircle}
          color={numberRequirement ? 'green.600' : 'red.200'}
          fontSize="24px"
          alignSelf="center"
        />

        <Text
          color={numberRequirement ? 'green.600' : 'red.200'}
          alignSelf="center"
        >
          Pelo menos 1 número
        </Text>
      </HStack>
      <HStack w="100%" alignContent="center">
        <Icon
          as={specialCharRequirement ? AiFillCheckCircle : AiFillCloseCircle}
          color={specialCharRequirement ? 'green.600' : 'red.200'}
          fontSize="24px"
          alignSelf="center"
        />

        <Text
          color={specialCharRequirement ? 'green.600' : 'red.200'}
          alignSelf="center"
        >
          Pelo menos 1 carácter especial
        </Text>
      </HStack>
    </VStack>
  );
};

export default PwdRequirements;
