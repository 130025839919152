import React, { useCallback, useState } from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io';
import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  Divider,
  Button,
  IconButton,
} from '@chakra-ui/react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { checkDateRange, handleMonths } from 'utils/functions';

interface MonthProps {
  name?: string;
  range: DateProps[];
  hover: DataHover;
  setRange: React.Dispatch<React.SetStateAction<DateProps[]>>;
  setHover: React.Dispatch<React.SetStateAction<DataHover>>;
}

const MonthPicker: React.FC<MonthProps> = ({
  name,
  range,
  hover,
  setRange,
  setHover,
}) => {
  const [year, setYear] = useState(new Date().getFullYear());

  const handleStart = useCallback(
    (day: string) => {
      const calendar = new Date(day);
      const { hoveredDate } = hover;
      const [{ startDate, endDate }] = range;

      if (!startDate || !endDate) return;

      if (hoveredDate) {
        if (hoveredDate.start >= startDate && calendar <= startDate)
          return true;

        if (
          calendar.getTime() === hoveredDate.start.getTime() &&
          hoveredDate.end <= endDate
        )
          return true;

        return;
      }

      if (calendar <= startDate) return true;

      return;
    },

    [range, hover],
  );

  const handleEnd = useCallback(
    (day: string) => {
      const calendar = new Date(day);
      const { hoveredDate } = hover;
      const [{ startDate, endDate }] = checkDateRange(range);

      if (!startDate || !endDate) return;

      if (hoveredDate) {
        if (hoveredDate.end <= endDate && calendar >= endDate) return true;

        if (
          calendar.getTime() === hoveredDate.end.getTime() &&
          hoveredDate.start >= startDate
        )
          return true;

        return;
      }

      if (calendar.getTime() >= endDate.getTime()) return true;

      return;
    },
    [range, hover],
  );

  const handleInterval = useCallback(
    (day: string) => {
      const calendar = new Date(day);
      const { hoveredDate } = hover;
      const [{ startDate, endDate }] = checkDateRange(range);

      if (!endDate || !startDate) return false;

      if (hoveredDate && calendar >= startDate && hoveredDate.end >= calendar)
        return true;

      if (hoveredDate && calendar <= startDate && hoveredDate.start <= calendar)
        return true;

      if (calendar >= startDate && calendar <= endDate) return true;
      return;
    },
    [range, hover],
  );

  const handlePicker = useCallback(
    (item: { abr: string; start: string; end: string }) => {
      const { isHovering } = hover;
      const [{ startDate, endDate }] = checkDateRange(range);

      if (!endDate || !startDate) return false;

      const start = new Date(item.start);
      const end = new Date(item.end);

      if (startDate && endDate && !isHovering) {
        setRange(() => [
          {
            key: 'selection',
            startDate: start,
            endDate: end,
          },
        ]);
        setHover({ hoveredDate: null, isHovering: true });
        return;
      }

      if (start > startDate) {
        setRange(state => [
          {
            ...state[0],
            key: 'selection',
            endDate: end,
          },
        ]);
      } else {
        setRange(state => [
          {
            ...state[0],
            key: 'selection',
            startDate: start,
          },
        ]);
      }
      setHover({ hoveredDate: null, isHovering: false });
    },
    [range, hover],
  );

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      mt="60px"
    >
      <Flex alignItems="center" justifyContent="center">
        <IconButton
          aria-label="Ir para ano passado"
          variant="ghost"
          onClick={() => setYear(state => state - 1)}
          icon={<IoIosArrowDropleft />}
          h="20px"
          w="20px"
          color="text.100"
          alignSelf="center"
          mr="auto"
          ml="16px"
        />
        <Text fontSize="md" color="text.100">
          {year}
        </Text>
        <IconButton
          aria-label="Ir para proximo ano"
          variant="ghost"
          onClick={() => setYear(state => state + 1)}
          icon={<IoIosArrowDropright />}
          h="20px"
          w="20px"
          color="text.100"
          alignSelf="center"
          ml="auto"
          mr="16px"
        />
      </Flex>
      <Divider aria-hidden="true" mt="8px" bg="grey.200" h="0.5px" />

      <Grid mx="16px" templateColumns="repeat(3, 1fr)">
        {handleMonths(year).map(item => {
          return (
            <GridItem
              mt="20px"
              key={item.abr}
              aria-hidden="true"
              fontWeight={400}
              as={Button}
              onClick={() => handlePicker(item)}
              bg={handleInterval(item.start) ? 'blue.100' : 'white'}
              borderLeftRadius={handleStart(item.start) ? '8px' : '0px'}
              borderRightRadius={handleEnd(item.end) ? '8px' : '0px'}
              onMouseEnter={() =>
                hover.isHovering &&
                setHover(state => ({
                  ...state,
                  hoveredDate: {
                    start: new Date(item.start),
                    end: new Date(item.end),
                  },
                }))
              }
              onMouseLeave={() =>
                hover.isHovering &&
                setHover(state => ({ ...state, hoveredDate: null }))
              }
              _hover={{
                bg: handleInterval(item.start) ? 'blue.100' : 'white',
                borderRightRadius: handleEnd(item.end) ? '8px' : '0px',
              }}
            >
              <Text
                data-cy={`picker-${item.abr}-${name}`}
                textAlign="center"
                fontSize="sm"
                color={handleInterval(item.start) ? 'white' : 'text.100'}
              >
                {item.abr}
              </Text>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MonthPicker;
