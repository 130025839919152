import React, { useEffect, useState } from 'react';
import { HStack } from '@chakra-ui/react';
import {
  setGlobalDate,
  setGlobalPeriod,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';
import DateRangeComp from 'components/Calendar';
import SegmentedPicker from 'components/SegmentedPicker';
import { useDateSelect } from 'hooks/useDateSelect';
import { useSegmentedPicker } from 'hooks/useSegmentedPicker';
import { isHeaderDateSelectShown } from 'utils/functions';

interface SideBarProps {
  path: string;
}

export const DateFilter: React.FC<SideBarProps> = ({ path }) => {
  const dispatch = useAppDispatch();

  const { globalDate } = useAppSelector(state => state.globalDataSlicer);

  const [pickedPeriod, setPickedPeriod] = useState('Mês');
  const { onSegmentedPickerChange } = useSegmentedPicker(setPickedPeriod);

  const [dateRange, setDateRange] = useState<DateProps[]>(globalDate);

  const { onDateRangeChange } = useDateSelect(setDateRange);

  useEffect(() => {
    const setGlobalDateFunc = () => {
      dispatch(setGlobalPeriod(pickedPeriod));
      dispatch(setGlobalDate(dateRange));
    };

    setGlobalDateFunc();
    setDateRange(dateRange);
  }, [dateRange, pickedPeriod]);

  useEffect(() => {
    if (dateRange !== globalDate) setDateRange(globalDate);
  }, [globalDate]);

  return (
    <HStack
      spacing={6}
      display={{ xs: 'flex', base: 'none' }}
      justify="center"
      mb="26px"
    >
      <DateRangeComp
        dateName="header"
        display={isHeaderDateSelectShown(path) ? 'flex' : 'none'}
        type={pickedPeriod}
        startDate={dateRange}
        ref={onDateRangeChange}
        isOnHeader
      />
      <SegmentedPicker
        pickerName="header"
        display={isHeaderDateSelectShown(path) ? 'flex' : 'none'}
        firstSelectedLabel={pickedPeriod}
        ref={onSegmentedPickerChange}
        labels={['Dia', 'Mês']}
      />
    </HStack>
  );
};
