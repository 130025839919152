import React from 'react';
import { Spinner } from '@chakra-ui/react';

export const Loading: React.FC = () => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="grey.200"
      color="ocean.200"
      size="xl"
      position="absolute"
      zIndex={1}
      alignSelf="center"
    />
  );
};
