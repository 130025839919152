import React, { useMemo } from 'react';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { RiHome6Line } from 'react-icons/ri';
import {
  Button,
  useTab,
  useMultiStyleConfig,
  Icon,
  VStack,
  Text,
  TabProps,
} from '@chakra-ui/react';

interface CustomTabProps extends TabProps {
  tabType: 'home' | 'help';
}

const CustomTab = React.forwardRef<HTMLDivElement | null, CustomTabProps>(
  (props, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];

    const styles = useMultiStyleConfig('Tabs', tabProps);

    const { name, icon } = useMemo(() => {
      switch (props.tabType) {
        case 'home':
          return {
            name: 'Início',
            icon: RiHome6Line,
          };
        case 'help':
          return {
            name: 'Ajuda',
            icon: IoHelpCircleOutline,
          };
      }
    }, []);

    return (
      <Button __css={styles.tab} {...tabProps} borderBottom="0px">
        <VStack>
          <Icon
            as={icon}
            color={isSelected ? 'blue.0' : 'black'}
            width="20px"
            height="20px"
          />
          <Text
            textColor={isSelected ? 'blue.0' : 'text.100'}
            mt="-10px"
            fontSize="14px"
          >
            {name}
          </Text>
        </VStack>
      </Button>
    );
  },
);

CustomTab.displayName = 'CustomTab';

export default CustomTab;
