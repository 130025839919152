import React, { useEffect, useState } from 'react';
import { IoIosHelp } from 'react-icons/io';
import { MdWhatsapp } from 'react-icons/md';
import {
  Button,
  IconButton,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import CustomTab from './components/customTab/customTab';
import HelpTab from './components/helpTab';
import HomeTab from './components/homeTab/home';

interface TourIcon {
  onClick: () => void;
  pageName: string;
}

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const TourIcon: React.FC<TourIcon> = ({ onClick, pageName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [index, setIndex] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [currentIcon, setCurrentIcon] = useState<number>(0);

  const icons = [
    <IoIosHelp key={1} size="40px" />,
    <MdWhatsapp key={2} size="24px" />,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIcon(prevIndex => (prevIndex + 1) % icons.length);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [icons.length]);

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      closeOnEsc
      closeOnBlur
      isLazy
      placement="top"
    >
      <PopoverTrigger>
        <IconButton
          as={Button}
          aria-label="tutorial guiado"
          zIndex={5}
          right="40px"
          bottom="20px"
          position="fixed"
          onClick={onOpen}
          borderRadius="80px"
          icon={
            <ChakraBox
              animate={{
                opacity: ['0%', '100%'],
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              transition={{
                duration: 0.8,
                ease: 'easeInOut',
              }}
              key={currentIcon}
            >
              {icons[currentIcon]}
            </ChakraBox>
          }
        />
      </PopoverTrigger>

      <Tabs index={index} onChange={setIndex}>
        <PopoverContent
          mr="36px"
          ml="20px"
          boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
          borderRadius="20px"
          w={{ sm: '400px', base: '320px' }}
          color="white"
          border="none"
        >
          <TabPanels m="0px" p="0px" h="600px">
            <TabPanel overflow="hidden" maxHeight="600px" m="0px" p="0px">
              <HomeTab
                onClick={onClick}
                onClose={onClose}
                setIndex={setIndex}
                setCategoryId={setCategoryId}
                pageName={pageName}
              />
            </TabPanel>
            <TabPanel overflow="hidden" maxHeight="600px" m="0px" p="0px">
              <HelpTab setCategoryId={setCategoryId} categoryId={categoryId} />
            </TabPanel>
          </TabPanels>
          <PopoverFooter
            borderBottomRadius="20px"
            bg="background"
            py="16px"
            px="32px"
            border="0"
            display="flex"
            pb={4}
            borderTop="1px solid "
            borderTopColor="grey.1000"
          >
            <TabList
              borderBottom="0px"
              w="100%"
              alignItems="center"
              justifyContent="space-around"
            >
              <CustomTab tabType="home" />
              <CustomTab tabType="help" />
            </TabList>
          </PopoverFooter>
        </PopoverContent>
      </Tabs>
    </Popover>
  );
};

export default TourIcon;
