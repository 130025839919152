import { defineStyleConfig } from '@chakra-ui/react';

export const Text = defineStyleConfig({
  baseStyle: {
    color: 'white',
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
  },
});
