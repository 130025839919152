import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface DelayAnimationProps {
  children: React.ReactNode;
}

export const DelayAnimation = ({ children }: DelayAnimationProps) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.1 }} // add delay here
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
