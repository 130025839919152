import React, { ReactNode } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineInstagram,
} from 'react-icons/ai';
import { HiPhone } from 'react-icons/hi';
import { MdOutlineEmail, MdOutlineWhatsapp } from 'react-icons/md';
import { VscLinkExternal } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  ImageProps,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import Footer1 from '../../../../assets/images/footer-1.png';
import Simpar1 from '../../../../assets/images/simpar-1.png';
import Tiete from '../../../../assets/images/tiete-1.svg';

interface SectionTextProps extends TextProps {
  title?: string;
  info?: string;
  link?: string;
}

interface SectionContainerProps extends StackProps {
  children?: ReactNode;
}

interface ImageLinkProps extends ImageProps {
  id: string;
  link: string;
  img: string;
}

const SectionTitle: React.FC<SectionTextProps> = ({
  title,
  link,
  ...props
}) => {
  if (link)
    return (
      <NavLink aria-label="Site  Grupo Vamos" to={link}>
        <Text fontWeight={800} color="text.100" fontSize="16px" {...props}>
          {title}
        </Text>
      </NavLink>
    );

  return (
    <Text fontWeight={800} color="text.100" fontSize="16px" {...props}>
      {title}
    </Text>
  );
};

const SectionInfo: React.FC<SectionTextProps> = ({ info, link, ...props }) => {
  if (link && (link.includes('tel:') || link.includes('whatsapp')))
    return (
      <NavLink aria-label="Telefone" to={link} target="_blank">
        <Flex alignItems="center">
          <Icon
            mr="8px"
            as={link.includes('whatsapp') ? MdOutlineWhatsapp : HiPhone}
            color="black"
            w="20px"
            h="20px"
          />

          <Text
            aria-hidden="true"
            fontSize="12px"
            color="text.100"
            {...props}
            _hover={{ textDecoration: 'underline' }}
          >
            {info}
          </Text>
        </Flex>
      </NavLink>
    );

  if (link)
    return (
      <NavLink
        data-cy={info}
        to={link}
        target="_blank"
        style={{ width: '25%' }}
      >
        <Button
          variant="footer"
          w="100%"
          ml="-5%"
          rightIcon={<VscLinkExternal color="transparent" />}
          justifyContent="space-between"
        >
          <Text fontWeight={400} color="text.100" fontSize="12px" {...props}>
            {info}
          </Text>
        </Button>
      </NavLink>
    );

  return (
    <Flex>
      {info?.includes('@') && (
        <Icon mr="8px" as={MdOutlineEmail} color="black" w="20px" h="20px" />
      )}

      <Text p="2px" w="100%" color="text.100" fontSize="12px" {...props}>
        {info}
      </Text>
    </Flex>
  );
};

const Section: React.FC<SectionContainerProps> = ({ children, ...props }) => {
  return (
    <VStack spacing="4px" alignItems="flex-start" w="100%" {...props}>
      {children}
    </VStack>
  );
};

const ImageLink: React.FC<ImageLinkProps> = ({ id, link, img, ...props }) => {
  return (
    <Box>
      <NavLink aria-label={id} data-cy={id} to={link} target="_blank">
        <Image alt="" {...props} objectFit="fill" src={img} />
      </NavLink>
    </Box>
  );
};

export const Footer = () => {
  return (
    <Flex data-cy="footer" w="100%" bg="grey.200" pl="34px">
      <Flex flexDir="column" pb="16px" flex={1}>
        <Flex
          pt="40px"
          borderBottom="1px solid"
          borderBottomColor="grey.200"
          gap="48px"
          mb="16px"
          pb="48px"
          mr="50px"
          pr="2%"
          ml="16px"
          flexWrap="wrap"
          position="relative"
        >
          <Box position="absolute" top={0}>
            <Image alt="" src={Footer1} />
          </Box>
          <VStack
            mr={{ md: '200px', base: '0px' }}
            ml={{ md: '154px', base: '0px' }}
            mt={{ md: '0px', base: '180px' }}
            spacing="40px"
            minW="24%"
            w={{ base: '100%', md: '24%' }}
            maxW="100%"
            alignItems="flex-start"
          >
            <Section flex={1}>
              <SectionTitle title="FALE CONOSCO" />
              <SectionInfo info="0800 025 4141" link="tel:08000254141" />
              <SectionInfo
                info="(11) 97837-9385"
                link="https://api.whatsapp.com/send?phone=5511978379385&text=Olá%20estou%20precisando%20de%20ajuda%20com%20o%20portal%20do%20cliente!"
              />
              <SectionInfo info="sac@grupovamos.com.br" />
            </Section>
            <Section flex={1}>
              <SectionTitle title="MATRIZ" />
              <SectionInfo info="Rua Dr. Renato Paes de Barros, 1017 6º andar - Itaim Bibi São Paulo/SP - CEP 04530-001" />
            </Section>

            <Flex flex={1}>
              <NavLink
                aria-label="Simpar"
                data-cy="simpar"
                to="https://simpar.com.br"
                target="_blank"
              >
                <Image alt="" h="50px" w="150px" src={Simpar1} />
              </NavLink>
            </Flex>
          </VStack>

          <VStack
            spacing="54px"
            alignItems="flex-start"
            w="24%"
            maxW="100%"
            flex={1}
          >
            <Section>
              <SectionTitle title="INSTITUCIONAL" />
              <SectionInfo info="Nosso Site" link="https://vamos.com.br/" />
            </Section>

            <Section>
              <SectionTitle title="SIGA O GRUPO VAMOS" />
              <HStack>
                <NavLink
                  aria-label="Facebook do Grupo Vamos"
                  data-cy="fb"
                  to="https://www.facebook.com/grupovamos.oficial"
                  target="_blank"
                >
                  <Icon
                    w="22px"
                    h="22px"
                    aria-hidden="true"
                    _hover={{ color: 'mutable.100' }}
                    minW={0}
                    color="text.100"
                    as={AiFillFacebook}
                  />
                </NavLink>
                <NavLink
                  aria-label="Instagram do Grupo Vamos"
                  data-cy="ig"
                  to="https://www.instagram.com/grupovamos_oficial"
                  target="_blank"
                >
                  <Icon
                    w="22px"
                    h="22px"
                    aria-hidden="true"
                    _hover={{ color: 'mutable.100' }}
                    minW={0}
                    color="text.100"
                    as={AiOutlineInstagram}
                  />
                </NavLink>
                <NavLink
                  aria-label="Linkedin do Grupo Vamos"
                  data-cy="in"
                  to="https://www.linkedin.com/company/grupo-vamos"
                  target="_blank"
                >
                  <Icon
                    w="22px"
                    h="22px"
                    aria-hidden="true"
                    _hover={{ color: 'mutable.100' }}
                    minW={0}
                    color="text.100"
                    as={AiFillLinkedin}
                  />
                </NavLink>
                <NavLink
                  aria-label="Youtube do Grupo Vamos"
                  data-cy="yt"
                  to="https://www.youtube.com/channel/UCAjWofuI6Pi5gTpL_AHOYYQ"
                  target="_blank"
                >
                  <Icon
                    w="22px"
                    h="22px"
                    aria-label="Youtube do Grupo Vamos"
                    _hover={{ color: 'mutable.100' }}
                    minW={0}
                    color="text.100"
                    as={AiFillYoutube}
                  />
                </NavLink>
              </HStack>
            </Section>

            <Flex
              w="100%"
              alignItems="flex-end"
              flex={1}
              flexWrap="wrap"
              gap={0}
            >
              <ImageLink
                w="82px"
                id="Vamos locação"
                link="https://vamos.com.br/locacao"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_Vamos_locacao_2f909e120b.svg"
              />

              <ImageLink
                w="82px"
                id="Vamos seminovos"
                link="https://vamos.com.br/seminovos"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_Vamos_Seminovos_d333bec5fb.svg"
              />

              <ImageLink
                w="82px"
                id="transrio"
                link="https://transrio.com.br/"
                img="	https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_Transrio_4b93ac9e71.svg"
              />

              <ImageLink
                w="82px"
                id="Vamos Maquinas"
                link="https://vamos.com.br/concessionarias/maquinas"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_Vamos_Maquinas_5d639cdefc.svg"
              />

              <ImageLink
                w="82px"
                id="Vamos concessionarias"
                link="https://vamos.com.br/concessionarias"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_Vamos_Agro_6cb7e8b398.svg"
              />

              <Box bg="button">
                <NavLink
                  aria-label="Tiete"
                  data-cy="Concessionaria tiete"
                  to="https://tiete.com.br/"
                  target="_blank"
                >
                  <Image fit="fill" alt="" h="56px" w="82px" src={Tiete} />
                </NavLink>
              </Box>

              <ImageLink
                w="82px"
                id="hm empilhadeiras"
                link="https://hmempilhadeiras.com.br/"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_HM_Empilhadeiras_4909e1dd9e.svg"
              />

              <ImageLink
                w="82px"
                mb="1.2px"
                id="modecenter"
                link="https://www.modecenter.com.br/"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/Logo_BMB_c8aa2bc3e6.svg"
              />

              <ImageLink
                h="57px"
                mb="0.4px"
                w="82px"
                id="Truckvan"
                link="https://www.truckvan.com.br/"
                img="https://gvmcmssiteinstitucionalprd.azurewebsites.net/uploads/regua_vamos_vermelha_2024_svg_removebg_preview_713008984d.png?format=webp&quality=90&w=undefined"
              />
            </Flex>
          </VStack>
        </Flex>
        <Text color="text.100" textAlign="center">
          {`Grupo Vamos ${new Date().getFullYear()} - Todos os direitos reservados`}
        </Text>
      </Flex>
    </Flex>
  );
};
