import React, { ReactNode } from 'react';
import { Text, Flex, Button, Center } from '@chakra-ui/react';

interface ComeBackHeaderProps {
  navigateTo?: () => void;
  title?: string;
  actionText?: string;
  customTitle?: ReactNode;
}

export const ComeBackHeader: React.FC<ComeBackHeaderProps> = ({
  navigateTo,
  title,
  actionText,
  customTitle,
}) => {
  return (
    <Flex
      bg="white"
      py="24px"
      px="18px"
      rounded="base"
      justify="space-between"
      align="center"
      mb="20px"
    >
      <Button onClick={navigateTo} variant="outline">
        {actionText}
      </Button>
      <Center w="100%">
        <Text fontSize="18px" color="GrayText">
          {title || customTitle}
        </Text>
      </Center>
    </Flex>
  );
};
