import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'grey.300',
    borderRadius: '6px',
    fontSize: '14px',
  },
});

const variants = {
  default: definePartsStyle({
    field: {
      bg: 'white',
      color: 'text.300',
      _placeholder: { color: 'text.300' },
    },
    icon: {
      color: 'text.300',
    },
  }),
};

export const Select = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: { variant: 'default' },
});
