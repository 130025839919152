import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';
import './styles.css';

export const HtmlStlyed: React.FC<{
  value?: string;
  title?: string;
  mt?: string;
  isWidget?: boolean;
}> = ({ value, title, mt, isWidget }) => {
  return (
    <Box mt={mt} w={isWidget ? '100%' : '80%'} mx="auto">
      <Text
        textAlign="start"
        fontWeight={600}
        fontSize={isWidget ? '18px' : '20px'}
        lineHeight="22px"
        textColor="text.100"
      >
        {title || 'Titulo indisponível'}
      </Text>
      <Flex className="html" flexDir="column" mt={isWidget ? '8px' : '24px'}>
        {parse(value || 'Conteúdo indisponível')}
      </Flex>
    </Box>
  );
};
