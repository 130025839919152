import React, { ReactNode } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Flex, FlexProps } from '@chakra-ui/react';

interface CardsProps extends FlexProps {
  children?: ReactNode;
  cardRef?: DraggableProvided['innerRef'];
  dragHandler?: DraggableProvided;
  cardOptions?: CardOptions;
}

export const Card: React.FC<CardsProps> = ({
  children,
  cardRef,
  dragHandler,
  minW,
  minH,
  cardOptions,
  ...rest
}) => {
  return (
    <Flex
      _hover={
        dragHandler && cardOptions?.widget.size === '50%'
          ? {
              borderColor: 'ocean.400',
            }
          : undefined
      }
      ref={cardRef}
      minW={minW ?? 293}
      minH={minH ?? 136}
      flexDir="column"
      justifyContent="center"
      bg="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
      border="1px solid"
      borderColor="grey.200"
      borderRadius={4}
      py="12px"
      px={4}
      transition="all 0.2s"
      {...dragHandler?.dragHandleProps}
      {...rest}
    >
      {children}
    </Flex>
  );
};
