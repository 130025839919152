import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  ModalOverlay,
  PopoverCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Modal,
  Text,
  Button,
  HStack,
  PopoverArrow,
  PlacementWithLogical,
  useMediaQuery,
  Image,
  BoxProps,
  Box,
  ModalHeader,
  Center,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  useAppDispatch,
  useSetStepMutation,
  setStep,
  setOnbFaq,
  setOnbFin,
  setOnbSchedule,
  setOnbFines,
  setOnbVehicle,
  setOnbLogs,
  setOnbModels,
  setOnbFleetDist,
  setOnbEntExit,
  setOnbServices,
  setOnbDocuments,
} from '@spartalabs/pdc-core';
import { useCustomToast } from 'hooks';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_ONBOARDING } from 'utils/constants';

interface OnboardingProps extends BoxProps {
  children?: ReactNode;
  step: number;
  isOpen: boolean;
  placement?: PlacementWithLogical;
  type:
    | 'dashboard'
    | 'Faq'
    | 'Fin'
    | 'fines'
    | 'vehicles'
    | 'schedules'
    | 'logs'
    | 'models'
    | 'fleetDist'
    | 'entExit'
    | 'services'
    | 'documents';
  contents: {
    title: string | null;
    subtitle?: JSX.Element | null;
    image?: string | null;
    last?: boolean | null;
    first?: boolean | null;
    bgColor?: string | null;
    isModal?: boolean | null;
  };
  customNext?: number;
}

export default function Onboarding({
  children,
  step,
  isOpen,
  placement,
  type,
  contents = {
    title: null,
    subtitle: null,
    image: null,
    last: null,
    first: null,
    bgColor: null,
    isModal: null,
  },
  customNext,
  ...props
}: OnboardingProps) {
  const [show, setShow] = useState(false);

  const [{ loadingForward, loadingBack }, setLoading] = useState({
    loadingForward: false,
    loadingBack: false,
  });

  const boxRef = useRef(null);
  const testeRef = useRef(null);

  const { title, subtitle, image, last, first, bgColor, isModal } = contents;

  const [isLargerThan980] = useMediaQuery('(min-width: 980px)');
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const dispatch = useAppDispatch();
  const { handleToast } = useCustomToast();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_ONBOARDING,
  });

  const [useStep, { isLoading }] = useSetStepMutation();

  const stepper = useCallback(
    (direction: number) => {
      switch (type) {
        case 'dashboard':
          return dispatch(setStep({ step: direction }));
        case 'Fin':
          return dispatch(setOnbFin(direction));
        case 'Faq':
          return dispatch(setOnbFaq(direction));
        case 'schedules':
          return dispatch(setOnbSchedule(direction));
        case 'fines':
          return dispatch(setOnbFines(direction));
        case 'vehicles':
          return dispatch(setOnbVehicle(direction));
        case 'logs':
          return dispatch(setOnbLogs(direction));
        case 'models':
          return dispatch(setOnbModels(direction));
        case 'fleetDist':
          return dispatch(setOnbFleetDist(direction));
        case 'entExit':
          return dispatch(setOnbEntExit(direction));
        case 'services':
          return dispatch(setOnbServices(direction));
        case 'documents':
          return dispatch(setOnbDocuments(direction));
      }
    },
    [type],
  );

  const handleStep = useCallback(
    async (direction: number) => {
      if (isLoading) return;

      try {
        if (direction > step)
          setLoading({ loadingForward: true, loadingBack: false });
        else setLoading({ loadingForward: false, loadingBack: true });

        if (type === 'dashboard') await useStep(direction).unwrap();

        //Onboarding Analytics
        if (step !== 4 && direction === 5) {
          analyticsEvent(`Fechado no modal ${title}`);
        }
        if (step === 4 && direction === 5) {
          analyticsEvent('Onboarding concluído');
        }

        stepper(direction);
      } catch {
        handleToast({
          step: 4,
          action: 'realizar o onboarding',
        });
      } finally {
        setLoading({ loadingForward: false, loadingBack: false });
      }
    },
    [isLoading, step],
  );

  const modalHelper = useMemo(() => {
    if (!isLargerThan768 && type !== 'dashboard') return true;
    else false;
  }, [type, isLargerThan768]);

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      boxRef?.current?.scrollIntoView?.({
        block: 'end',
        behavior: 'smooth',
      });

      setTimeout(() => {
        setShow(true);
        document.body.style.overflowY = 'hidden';
      }, 300);
    } else {
      document.body.style.overflowY = 'scroll';

      setShow(false);
    }
  }, [isOpen, boxRef]);

  return isModal || modalHelper ? (
    <Box
      zIndex={1}
      pointerEvents={show ? 'none' : 'auto'}
      ref={boxRef}
      {...props}
    >
      <Modal isCentered isOpen={show} onClose={() => null}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '90%', md: '548px' }} bg="white">
          <ModalCloseButton
            onClick={() => handleStep(999)}
            color="black"
            size="md"
            bg="white"
            _hover={{ bg: 'grey.200' }}
          />
          <ModalHeader
            p="0px"
            fontSize="md"
            fontWeight={800}
            color="black"
            textAlign="center"
          >
            {image && (
              <Center minH="225px" bg={bgColor || 'white'} borderRadius="8px">
                <Image src={image} borderTopRadius="4px" />
              </Center>
            )}
            <Text
              mt="32px"
              textAlign="center"
              color="text.400"
              fontSize="16px"
              fontWeight="600"
            >
              {title}
            </Text>
          </ModalHeader>
          <ModalBody>{subtitle}</ModalBody>

          <ModalFooter mt="24px" pt="0px">
            {!first && (
              <Button variant="outline" onClick={() => handleStep(step - 1)}>
                Voltar
              </Button>
            )}
            {last ? (
              <Button ml="12px" onClick={() => handleStep(999)}>
                Finalizar
              </Button>
            ) : (
              <Button ml="12px" onClick={() => handleStep(step + 1)}>
                {first ? 'Iniciar' : 'Próximo'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children && children}
    </Box>
  ) : (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      placement={isLargerThan980 ? placement : 'bottom'}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box
          pointerEvents={show ? 'none' : 'auto'}
          zIndex={show ? 9999 : 2}
          ref={boxRef}
          {...props}
        >
          {children}
        </Box>
      </PopoverTrigger>
      <Modal isOpen={show} onClose={() => null}>
        <ModalOverlay>
          <PopoverContent
            ref={testeRef}
            w={{ base: '360px', md: '548px' }}
            p="24px"
          >
            <PopoverArrow />
            <PopoverCloseButton
              onClick={() => handleStep(999)}
              size="16px"
              color="black"
            />
            <Text fontWeight={600} color="text.400">
              {title}
            </Text>

            {subtitle}

            {image && <Image mt="40px" h="260px" w="100%" src={image} />}

            <HStack ml="auto" mt="40px">
              {!first && (
                <Button
                  isDisabled={loadingForward}
                  isLoading={loadingBack}
                  onClick={() => handleStep(step - 1)}
                  variant="outline"
                >
                  Voltar
                </Button>
              )}
              <Button
                isDisabled={loadingBack}
                isLoading={loadingForward}
                onClick={() => handleStep(customNext ? customNext : step + 1)}
              >
                {last ? 'Finalizar' : 'Próximo'}
              </Button>
            </HStack>
          </PopoverContent>
        </ModalOverlay>
      </Modal>
    </Popover>
  );
}
