import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Box, Center, DrawerOverlay, Icon } from '@chakra-ui/react';
import { Drawer, DrawerContent, Text } from '@chakra-ui/react';
import { BaseFilters } from 'components/Filters/BaseFilters';
import { DateFilter } from 'components/Filters/DateFilters';

interface SideBarProps {
  path: string;
  isOpen: boolean;
  onClose: () => void;
}

export const FilterMenu: React.FC<SideBarProps> = ({
  path,
  isOpen,
  onClose,
}) => {
  return (
    <Drawer
      closeOnOverlayClick
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size={{ base: 'xs', sm: 'sm' }}
    >
      <DrawerOverlay />
      <DrawerContent bg="grey.200">
        <Center
          data-cy="close-filter"
          onClick={onClose}
          as="button"
          top="68px"
          border="4px solid"
          borderColor="grey.100"
          borderRadius="20px"
          left={-5}
          bg="white"
          position="absolute"
          w="40px"
          h="40px"
        >
          <Icon w="24px" h="8" color="black" as={IoIosArrowForward} />
        </Center>
        <Box
          bg="grey.100"
          sx={{
            '&::-webkit-scrollbar': {
              borderRadius: '8px',
              backgroundColor: 'grey.100',
              display: 'nome',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.300',
            },
          }}
          overflowX="scroll"
          px="40px"
          pt="74px"
          h="100%"
        >
          <Text fontWeight={500} color="text.100" fontSize="20px" mb="16px">
            Filtrar
          </Text>
          <DateFilter path={path} />
          <BaseFilters />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
