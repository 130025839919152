import React from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { Center, Icon } from '@chakra-ui/react';
import { setColor, useAppDispatch } from '@spartalabs/pdc-core';
import { useCustomToast } from 'hooks';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { CUSTOMIZE_COLOR_LABEL } from 'utils/constants';
import { translateColor } from 'utils/functions';

interface CustomColorProps {
  color: string;
  selected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutateColor: any;
  isLoading: boolean;
}

export const CustomColor: React.FC<CustomColorProps> = ({
  color,
  selected,
  mutateColor,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const { handleToast } = useCustomToast();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: CUSTOMIZE_COLOR_LABEL,
  });

  return (
    <Center
      aria-label={`Mudar cor para ${
        color === 'red' ? 'vermelho' : color === 'black' ? 'preto' : 'azul'
      }`}
      data-cy={`color-${color}`}
      onClick={async () => {
        if (selected || isLoading) return;
        try {
          analyticsEvent(`Cor de Fundo Alterada para ${translateColor(color)}`);

          await mutateColor(color);
          dispatch(setColor({ color }));
        } catch {
          handleToast({
            step: 4,
            action: 'trocar de cor',
          });
        }
      }}
      border={`2px solid ${selected ? 'white' : 'transparent'}`}
      as="button"
      bg={color}
      w="40px"
      h="40px"
      borderRadius="20px"
    >
      {selected && <Icon h="24px" w="24px" as={BsCheck2} />}
    </Center>
  );
};
