import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

interface NavTextProps {
  path?: string | null;
  isActive: boolean;
  name: string;
}

export const NavText = ({ path, isActive, name }: NavTextProps) => {
  if (path)
    return (
      <Box
        fontSize="md"
        opacity={isActive ? '100%' : '50%'}
        _hover={{ opacity: '100%' }}
      >
        <NavLink to={path}>
          <Text noOfLines={name.length > 15 ? undefined : 1}>{name}</Text>
        </NavLink>
      </Box>
    );

  return (
    <Text
      pt={1}
      fontSize="md"
      color="white"
      opacity={isActive ? '100%' : '90%'}
      noOfLines={name.length > 15 ? undefined : 1}
    >
      {name}
    </Text>
  );
};
