import { useCallback } from 'react';

export function useSegmentedPicker(
  setPicked: React.Dispatch<React.SetStateAction<string>> | undefined,
) {
  const onSegmentedPickerChange = useCallback((node: string) => {
    if (setPicked && node) setPicked(node);
  }, []);

  return { onSegmentedPickerChange };
}
