import ReactGA from 'react-ga4';
import { GA4_KEY } from 'utils/constants';
import { isAllowed, isProd } from 'utils/functions';

interface AnalyticsBaseEvent {
  category: string;
  action: string;
}

const useAnalyticsEvent = ({ category, action }: AnalyticsBaseEvent) => {
  return (label: string) => {
    if (isAllowed() && !ReactGA.isInitialized) ReactGA.initialize(GA4_KEY);

    if (isProd() && isAllowed())
      ReactGA.event({
        category,
        action,
        label,
      });
  };
};

export default useAnalyticsEvent;
