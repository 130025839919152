import React, { forwardRef } from 'react';
import { IoMdColorWand } from 'react-icons/io';
import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';

interface CustomColorProps extends FlexProps {
  text: string;
  isDragging: boolean;
}

export const CustomWidget = forwardRef<HTMLDivElement | null, CustomColorProps>(
  (props, ref) => {
    const { text, isDragging } = props;

    return (
      <Flex
        ref={ref}
        alignItems="center"
        justifyContent="space-arround"
        border="2px solid white"
        bg={isDragging ? 'button' : undefined}
        {...props}
      >
        <Icon w="18px" h="18px" mx="8px" as={IoMdColorWand} />
        <Text my="12px" mr="8px" fontSize="14px">
          {text}
        </Text>
      </Flex>
    );
  },
);

CustomWidget.displayName = 'CustomWidget';

export default CustomWidget;
