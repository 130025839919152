/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import {
  chartDaysLabels,
  chartDaysLabelsMocked,
  chartMonthsLabels,
  chartMonthsLabelsMocked,
} from 'utils/functions';

interface chartValue {
  label: string;
  borderColor?: string;
  fill?: {
    type: string;
    color?: string | string[];
  };
  accessor: any;
  values?: any;
}

export function useChart() {
  const handleChartData = useCallback(
    (chartLabels: string[], chartValues: chartValue[]) => {
      return chartValues.map((chartValue: chartValue) => ({
        label: chartValue.label,
        borderColor: chartValue.borderColor,
        fill: chartValue.fill,
        values:
          chartValue.values.length === 0
            ? chartLabels.map(() => 0)
            : chartLabels.map((chartLabel, index) =>
                chartValue.values?.[index]
                  ? chartValue.values?.[index][chartValue.accessor]
                  : 0,
              ),
      })) as ChartData[] | MultiChartData[];
    },
    [],
  );

  const handleChartLabels = useCallback(
    (pickedPeriod: string, data: any, startDate?: Date, endDate?: Date) => {
      if (Array.isArray(data)) {
        if (data.length === 0 && startDate && endDate) {
          if (pickedPeriod === 'Dia') {
            return chartDaysLabelsMocked(startDate, endDate);
          } else {
            return chartMonthsLabelsMocked(startDate, endDate);
          }
        }
        const dates = data.map((element: any) => element.id);
        if (pickedPeriod === 'Dia') {
          return chartDaysLabels(dates);
        } else {
          return chartMonthsLabels(dates);
        }
      }

      if (data.itens.length === 0 && startDate && endDate) {
        if (pickedPeriod === 'Dia') {
          return chartDaysLabelsMocked(startDate, endDate);
        } else {
          return chartMonthsLabelsMocked(startDate, endDate);
        }
      }

      const dates = data.itens.map((element: any) => element.id);
      if (pickedPeriod === 'Dia') {
        return chartDaysLabels(dates);
      } else {
        return chartMonthsLabels(dates);
      }
    },
    [],
  );

  return { handleChartData, handleChartLabels };
}
