import * as React from 'react';
import { HiOutlineExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Flex, Icon, Text, Button } from '@chakra-ui/react';

export const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Flex
      h="50vh"
      direction="column"
      alignItems="center"
      justifyContent="center"
      fontSize="xl"
    >
      <Icon
        borderRadius="100px"
        padding="16px"
        bg="grey.200"
        w="96px"
        h="96px"
        as={HiOutlineExclamation}
        color="grey.600"
      />
      <Text fontWeight="medium" color="text.100" fontSize="24px">
        Autorização necessária
      </Text>
      <Text color="text.300" fontSize="16px">
        Acesso sem permissão para essa seção
      </Text>
      <Button
        mt="24px"
        fontSize="14px"
        variant="outline"
        onClick={() => navigate('/inicio')}
      >
        Voltar para página anterior
      </Button>
    </Flex>
  );
};
