import React from 'react';
import {
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Portal,
  Spinner,
} from '@chakra-ui/react';

export const LoadingModal: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  return (
    <Portal>
      <Modal isOpen={isOpen} onClose={() => null} isCentered>
        <ModalOverlay />
        <ModalContent
          bg="transparent"
          border="none"
          shadow="none"
          h="100px"
          w="100px"
        >
          <ModalBody justifyContent="center" alignItems="center">
            <Center h="100%">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="grey.200"
                color="ocean.200"
                size="xl"
                alignSelf="center"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Portal>
  );
};
