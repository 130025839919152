import React, { useState, ReactNode, useMemo } from 'react';
import { BiTrafficCone } from 'react-icons/bi';
import { BsShieldCheck } from 'react-icons/bs';
import { IoIosArrowDown, IoIosSettings, IoMdGlobe } from 'react-icons/io';
import { MdOutlineBuild, MdTimeToLeave } from 'react-icons/md';
import { VscLinkExternal } from 'react-icons/vsc';
import {
  Flex,
  Text,
  VStack,
  Icon,
  Button,
  FlexProps,
  HStack,
  VisuallyHidden,
} from '@chakra-ui/react';
import DateRangeComp from 'components/Calendar';
import { Loading } from 'components/Loading';
import { MultiInfoSkeleton } from 'components/Skeletons';
import { useDateSelect } from 'hooks/useDateSelect';
import { DETAILS_LABEL } from 'utils/constants';
import { generateId, typeFilterLabelTranslate } from 'utils/functions';
import { Card } from './Card';
import { CardOptionsMenu } from './CardOptionsMenu';
import { ErrorCard } from './ErrorCard';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface MultiInfoCardProps extends FlexProps {
  title?: string;
  customTitle?: ReactNode;
  subtitleMb?: string | number;
  subtitle?: string;
  hasFilter?: boolean;
  picker?: string[];
  pickerType?: string;
  firstPicked?: string;
  setDateRange?: React.Dispatch<React.SetStateAction<DateProps[]>>;
  startDate?: DateProps[];
  details?: () => void;
  data: RepairChart | undefined;
  canHaveShowMore?: boolean;
  loading?: boolean;
  fetching?: boolean;
  showTotal?: boolean;
  cardName?: string;
  isError?: boolean;
  refetch?: any;
  cardOptions?: CardOptions;
  cardRef?: any;
}

const getIcon = (title: string) => {
  if (title === 'corective') return MdOutlineBuild;

  if (title === 'preventive') return BsShieldCheck;

  if (title === 'sinister') return BiTrafficCone;

  if (title === 'others') return IoIosSettings;

  if (title === 'total') return IoMdGlobe;

  return MdTimeToLeave;
};

function MultiCardItem({
  title,
  percetageVehicle,
  countVehicle,
}: {
  title: string;
  percetageVehicle: string;
  countVehicle: string;
}) {
  const isNan = isNaN(Number(percetageVehicle));

  return (
    <VStack w="225px" display="flex" align="flex-start">
      <Flex alignItems="flex-start">
        <Flex
          bg="grey.200"
          align="center"
          justify="center"
          mr={2}
          p="6px"
          borderRadius="4px"
        >
          <Icon
            aria-hidden="true"
            as={getIcon(title)}
            color="button"
            h="16px"
            w="16px"
            alignSelf="center"
          />
        </Flex>
        <Text
          textAlign="start"
          aria-hidden="true"
          color="text.100"
          fontSize="14px"
          fontWeight={500}
          css={{
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {typeFilterLabelTranslate(title)}
        </Text>
      </Flex>
      <Flex>
        <VisuallyHidden>
          <Text>{typeFilterLabelTranslate(title)}</Text>
        </VisuallyHidden>
        <Text color="text.300" fontSize="14px" fontWeight={500} mr={2}>
          {isNan ? 'Valor indisponível' : `${percetageVehicle}% da frota`}
        </Text>
        <Text
          color="green.600"
          fontSize="10px"
          fontWeight={600}
          alignSelf="center"
        >
          {!isNan && countVehicle}
        </Text>
      </Flex>
    </VStack>
  );
}

export const MultiInfoCard: React.FC<MultiInfoCardProps> = ({
  data,
  loading,
  fetching,
  showTotal,
  details,
  subtitle,
  title,
  customTitle,
  setDateRange,
  startDate,
  canHaveShowMore,
  cardName,
  isError,
  refetch,
  cardOptions,
  cardRef,
  ...rest
}) => {
  const { onDateRangeChange } = useDateSelect(setDateRange);

  const [showMore, setShowMore] = useState(false);

  const handleDisplayData: {
    newArr: [string, number][];
    total: number;
    canShowMore: boolean;
  } | null = useMemo(() => {
    if (!data) return null;

    const newArr: [string, number][] = Object.entries(data.count);
    const total: number = data.count.total;
    const canShowMore: boolean = canHaveShowMore ? newArr.length > 6 : false;

    return {
      newArr: !showMore ? newArr.slice(0, 6) : newArr,
      total,
      canShowMore,
    };
  }, [showMore, data]);

  if (isError)
    return <ErrorCard {...rest} refetch={() => (refetch ? refetch() : null)} />;

  if (loading)
    return (
      <Card
        p="0px"
        cardOptions={cardOptions}
        cardRef={cardRef}
        dragHandler={cardOptions?.dragHandler}
        {...rest}
      >
        <MultiInfoSkeleton />
      </Card>
    );

  return (
    <Card
      cardOptions={cardOptions}
      cardRef={cardRef}
      dragHandler={cardOptions?.dragHandler}
      {...rest}
    >
      {fetching && (
        <Flex position="absolute" zIndex={2} alignSelf="center">
          <Loading />
        </Flex>
      )}
      <Flex flex={1} align="flex-start" justify="center" flexDir="column">
        <Flex w="100%" justifyContent="space-between" justifySelf="flex-start">
          {customTitle ? (
            customTitle
          ) : (
            <Text
              alignSelf="center"
              color="text.100"
              fontSize="16px"
              fontWeight={400}
            >
              {title}
            </Text>
          )}

          <HStack flexWrap="wrap" justify="flex-end" spacing="16px">
            {setDateRange && (
              <DateRangeComp
                display={{ md: 'flex', base: 'none' }}
                dateName={cardName}
                type="month"
                startDate={startDate}
                ref={onDateRangeChange}
                minW="170px"
              />
            )}

            {!!details && (
              <Button
                display={{ md: 'flex', base: 'none' }}
                data-cy={`details-${cardName}`}
                onClick={details}
                variant="link"
                rightIcon={<VscLinkExternal />}
              >
                {DETAILS_LABEL}
              </Button>
            )}
            {!!cardOptions && <CardOptionsMenu values={cardOptions} />}
          </HStack>
        </Flex>
        <Text
          mb={{ md: '32px', base: '0px' }}
          color="text.300"
          fontSize="12px"
          fontWeight={400}
        >
          {subtitle}
        </Text>

        <HStack
          display={{ md: 'none', base: 'flex' }}
          flexWrap="wrap"
          spacing="16px"
          mt="8px"
          mb="20px"
        >
          {!!details && (
            <Button
              data-cy={`details-${cardName}`}
              onClick={details}
              variant="link"
              rightIcon={<VscLinkExternal />}
            >
              {DETAILS_LABEL}
            </Button>
          )}

          {setDateRange && (
            <DateRangeComp
              dateName={cardName}
              type="month"
              startDate={startDate}
              ref={onDateRangeChange}
              minW="170px"
            />
          )}
        </HStack>

        {handleDisplayData && (
          <>
            <Flex
              gap={4}
              justifyContent={{
                base: 'start',
                '2xl':
                  handleDisplayData.newArr.length === 5
                    ? 'space-between'
                    : 'start',
              }}
              alignItems="flex-start"
              flexWrap="wrap"
              w="100%"
            >
              {handleDisplayData.newArr?.map(([key, value]) => {
                const { total } = handleDisplayData;
                const percetageVehicle = (
                  (Number(value) / Number(total)) *
                  100
                ).toFixed(2);
                const countVehicle = `${String(value).padStart(
                  2,
                  '0',
                )}/${total}`;
                if (showTotal) {
                  return (
                    <MultiCardItem
                      key={generateId()}
                      title={key}
                      percetageVehicle={percetageVehicle}
                      countVehicle={countVehicle}
                    />
                  );
                }
                if (key !== 'total') {
                  return (
                    <MultiCardItem
                      key={generateId()}
                      title={key}
                      percetageVehicle={percetageVehicle}
                      countVehicle={countVehicle}
                    />
                  );
                }
              })}
            </Flex>
            {handleDisplayData.canShowMore && (
              <>
                <Button
                  alignSelf="start"
                  variant="ghost"
                  minW={0}
                  p="24px 0 8px"
                  minH={0}
                  onClick={() => setShowMore(state => !state)}
                  h="100%"
                >
                  <Text color="text.100" fontWeight={400} mr="8px">
                    Ver Todos
                  </Text>
                  <Icon
                    as={IoIosArrowDown}
                    color="text.100"
                    transform={showMore ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="all .5s ease"
                  />
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
    </Card>
  );
};
