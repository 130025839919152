import React, { useCallback, useMemo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { BsPalette } from 'react-icons/bs';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Box,
  Button,
  Center,
  DrawerOverlay,
  Grid,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { Drawer, DrawerContent, Text } from '@chakra-ui/react';
import {
  setColor,
  setDefaultWidgets,
  useAppDispatch,
  useAppSelector,
  useSetColorMutation,
} from '@spartalabs/pdc-core';
import { Card } from 'components/Cards/Card';
import { LoadingModal } from 'components/LoadingModal';
import Onboarding from 'components/Onboarding';
import { useCustomToast } from 'hooks';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_CUSTOMIZE_WIDGETS_LABEL } from 'utils/constants';
import { generateId } from 'utils/functions';
import { dashboardTexts } from 'utils/texts';
import { CustomColor } from '../CustomColors';
import { CustomWidget } from '../CustomWidgets';

interface SideBarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CustomMenu: React.FC<SideBarProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const widgetsList = useAppSelector(
    state => state.globalDataSlicer.widgetsList,
  );

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_CUSTOMIZE_WIDGETS_LABEL,
  });

  const [mutateColor, { isLoading }] = useSetColorMutation();

  const { handleToast } = useCustomToast();

  const { color: userColor, step } = useAppSelector(
    state => state.authSlicer.user,
  );

  const isOnboarding = useMemo(() => step === 2, [step]);

  const colors = ['button', 'red', 'black'];

  const handleDefaultChange = useCallback(async () => {
    if (isLoading) return;
    try {
      analyticsEvent('Redefinido para layout padrão');
      await mutateColor('button');
      dispatch(setColor({ color: 'button' }));
      dispatch(setDefaultWidgets());
    } catch {
      handleToast({
        step: 4,
        action: 'restaurar o tema',
      });
    }
  }, [isLoading]);

  return (
    <Drawer
      closeOnOverlayClick
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size={{ base: 'xs', sm: 'sm' }}
    >
      <LoadingModal isOpen={isLoading} />
      <DrawerOverlay />
      <DrawerContent data-cy="menu-body" bg="mutable.100">
        <Onboarding
          type="dashboard"
          placement="left-end"
          step={2}
          isOpen={isOnboarding}
          contents={dashboardTexts.step2}
        >
          <Center
            data-cy="custom-menu-close"
            onClick={onClose}
            as="button"
            top="68px"
            border="4px solid"
            borderColor="mutable.100"
            borderRadius="20px"
            left={-5}
            bg="white"
            position="absolute"
            w="40px"
            h="40px"
          >
            <Icon w="24px" h="8" color="black" as={IoIosArrowForward} />
          </Center>
        </Onboarding>
        <Box
          bg="mutable.100"
          sx={{
            '&::-webkit-scrollbar': {
              borderRadius: '8px',
              backgroundColor: 'mutable.100',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'mutable.200',
            },
          }}
          overflowX="scroll"
          px="28px"
          pt="60px"
          h="100%"
        >
          <Text fontSize="20px">Personalize a página inicial</Text>
          <Text fontSize="14px">Cor de fundo</Text>
          <Grid w="10px" gap="8px" templateColumns="repeat(3, 1fr)" mt="24px">
            {colors.map(color => (
              <CustomColor
                isLoading={isLoading}
                mutateColor={mutateColor}
                key={color}
                color={color}
                selected={color === userColor}
              />
            ))}
          </Grid>
          <Button
            onClick={handleDefaultChange}
            _loading={{ bg: '#FFF', opacity: 1 }}
            _hover={{ bg: 'grey.200' }}
            _active={{ bg: 'grey.500' }}
            w="full"
            fontWeight={400}
            mt="24px"
            bg="white"
            color="black"
            leftIcon={<Icon color="black" as={BsPalette} />}
            disabled={isLoading}
          >
            Redefinir layout padrão
          </Button>
          <Text mt="40px" fontSize="20px">
            Quadro
          </Text>
          <Text textAlign="justify" fontSize="14px">
            Clique e arraste o quadro desejado para a página inicial,
            posicionando no local que facilite a organização do seu dashboard.
          </Text>

          <Droppable
            droppableId="widgets"
            isDropDisabled={true}
            type="DroppableRows"
            renderClone={provided => (
              <Card
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                cardRef={provided.innerRef}
                h="50px"
                w="2000px"
                opacity="80%"
              />
            )}
          >
            {provided => {
              return (
                <Stack
                  mt="24px"
                  spacing="16px"
                  flex={1}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {widgetsList.map(({ id, type }, index) => (
                    <Draggable
                      key={generateId()}
                      draggableId={`widget${id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <CustomWidget
                          aria-hidden="true"
                          isDragging={snapshot.isDragging}
                          text={type}
                          position="static"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                </Stack>
              );
            }}
          </Droppable>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
