import React, { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import {
  skeletonBarData,
  skeletonLabels,
  skeletonLineData,
} from 'utils/constants';

export const ChartSkele: React.FC<{ type?: string }> = ({ type }) => {
  const BarSkele = useCallback(() => {
    return (
      <BarChart w="800px" labels={skeletonLabels} data={skeletonBarData} />
    );
  }, [type]);

  const LineSkele = useCallback(() => {
    return (
      <LineChart w="800px" labels={skeletonLabels} data={skeletonLineData} />
    );
  }, [type]);

  return (
    <Flex h="100%" w="100%">
      {type && type === 'bar' ? BarSkele() : LineSkele()}
    </Flex>
  );
};
