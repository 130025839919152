import { extendTheme } from '@chakra-ui/react';
import { Button } from './components/Button';
import { Checkbox } from './components/Checkbox';
import { colors } from './components/colors';
import { fonts } from './components/font';
import { Input } from './components/Input';
import { Select } from './components/Select';
import { Text } from './components/Text';

const breakpoints = {
  xs: '23em', // 368px
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
};

const overrides = {
  breakpoints,
  styles: {
    global: {
      'html, body': {
        color: 'white',
      },
      p: {
        color: 'text.100',
        textAlign: 'justify',
      },
    },
  },
  colors,
  fonts,
  components: {
    Checkbox,
    Select,
    Button,
    Input,
    Text,
  },
};

export default extendTheme(overrides);
