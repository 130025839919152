import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '6px',
  },

  sizes: {
    md: {
      fontSize: '14px',
    },
    sm: {
      fontSize: '12px',
      height: '34px',
    },
  },

  variants: {
    default: {
      transition: 'none',
      color: 'white.0',
      bg: 'mutable.100',
      _hover: { bg: 'mutable.200', _loading: { bg: 'mutable.100' } },
      _active: { bg: 'mutable.200' },
      _loading: { bg: 'button', color: 'white.0' },
    },
    switchNotSelected: {
      border: '1px solid',
      borderColor: 'grey.500',
      color: 'grey.500',
      _hover: { border: '2.5px solid' },
      _active: { border: '2px solid' },
    },
    outline: {
      border: '2px solid',
      borderColor: 'mutable.100',
      color: 'mutable.100',
      _hover: { bg: 'grey.300' },
      _active: { border: '2px solid' },
      _loading: { color: 'mutable.100' },
    },
    outlineGray: {
      border: '2px solid',
      borderColor: 'grey.500',
      color: 'grey.500',
      _hover: { borderColor: 'grey.500', bg: 'grey.500' },
      _active: {
        borderColor: 'mutable.100',
        bg: 'mutable.100',
        color: 'white',
      },
    },
    ghost: {
      bg: 'none',
      _hover: { bg: 'none' },
    },
    disable: {
      bg: 'grey.500',
      color: 'grey.500',
    },
    link: {
      color: 'blue.0',
      fontWeight: 400,
      _active: { color: 'blue.0' },
    },
    footer: {
      minW: 0,
      py: '4px',
      px: '5%',
      rounded: 'full',
      minH: 0,
      color: 'text.100',
      h: '100%',
      bg: 'transparent',
      _hover: {
        bg: 'grey.500',
        textDecoration: 'underline',
        svg: { fill: 'grey.600' },
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
  },
});
