import React, { useCallback, useMemo } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FiHome } from 'react-icons/fi';
import {
  HiOutlineLocationMarker,
  HiOutlineDocumentText,
  HiOutlineUsers,
  HiOutlineSupport,
} from 'react-icons/hi';
import { IoIosRepeat, IoLogoUsd } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdTimeToLeave } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { Box, Collapse, Icon, Menu } from '@chakra-ui/react';
import { useAppSelector } from '@spartalabs/pdc-core';
import { NavButton } from '../NavButton/NavButton';

export interface tabContent {
  name: string;
  path: string;
  isDropable?: boolean;
}

export interface optionProps {
  fp: string[];
  sp: string[];
  tp: string[];
}

export interface dropContent extends tabContent {
  insideDrops?: tabContent[];
}

interface NavBtnProps extends tabContent {
  dropContent?: dropContent[];
  option: optionProps;
  setOption: (item: optionProps) => void;
  isCollapsed: boolean;
  onClose: () => void;
}

const ICON_W = '24px';
const ICON_H = '24px';

export const NavBtn = ({
  name,
  path,
  isDropable,
  dropContent,
  option,
  setOption,
  isCollapsed,
  onClose,
}: NavBtnProps) => {
  const { pathname } = useLocation();
  const accessed = useAppSelector(state => state.authSlicer.user.accessedAreas);

  const menuIcon = useCallback(() => {
    switch (name) {
      case 'Início':
        return (
          <Icon
            as={FiHome}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );
      case 'Relatórios':
        return (
          <Icon
            as={HiOutlineDocumentText}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );
      case 'Agendamentos':
        return (
          <Icon
            as={AiOutlineClockCircle}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );
      case 'Vamos Controle':
        return (
          <Icon
            as={HiOutlineLocationMarker}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );
      case 'Faturamento':
        return (
          <Icon
            as={IoLogoUsd}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );

      case 'Acessos':
        return (
          <Icon
            as={HiOutlineUsers}
            h={ICON_H}
            w={ICON_W}
            color="white"
            alignSelf="center"
          />
        );
      case 'Central de Dúvidas':
        return (
          <Icon
            as={HiOutlineSupport}
            h="20px"
            w="20px"
            color="white"
            alignSelf="center"
          />
        );
      case 'Gestão de contratos':
        return (
          <Icon
            as={IoDocumentTextOutline}
            h="20px"
            w="20px"
            color="white"
            alignSelf="center"
          />
        );

      case 'Log de ações':
        return (
          <Icon
            as={IoIosRepeat}
            h="20px"
            w="20px"
            color="white"
            alignSelf="center"
          />
        );
      case 'Gestão de Veículos':
        return (
          <Icon
            as={MdTimeToLeave}
            h="20px"
            w="20px"
            color="white"
            alignSelf="center"
          />
        );

      default:
        return '';
    }
  }, [name]);

  const isNew = useMemo(() => {
    const maximumDate = new Date('06/01/2024').getTime();

    const newFeatures = [
      'Faturamento',
      'Gestão de contratos',
      'Gestão de Veículos',
    ];

    const nameFormatter = name === 'Gestão de Veículos' ? 'CRLV' : name;

    if (
      Date.now() < maximumDate &&
      !accessed?.includes(nameFormatter) &&
      newFeatures.includes(name)
    )
      return true;

    return false;
  }, [name, accessed]);

  const handleSelect = (picks: string[], picked: string) => {
    if (picks.includes(picked)) return picks.filter(item => item !== picked);

    return [...picks, picked];
  };

  const handlePick = useCallback(
    (pick: 1 | 2 | 3, name: string) => {
      if (pick === 1)
        setOption({
          ...option,
          fp: handleSelect(option.fp, name),
        });
      else if (pick === 2)
        setOption({
          ...option,
          sp: handleSelect(option.sp, name),
        });
    },
    [setOption, option],
  );

  const handleActive = useCallback(
    (itemPath: string) => {
      if (pathname.split('/').includes(itemPath?.split('/').slice(-1)[0]))
        return true;

      return false;
    },
    [pathname],
  );

  return (
    <Menu>
      <Box>
        <NavButton
          handlePick={() => handlePick(1, name)}
          isActive={handleActive(path)}
          activeColor="mutable.200"
          name={name}
          path={path}
          option={option}
          isCollapsed={isCollapsed}
          isDroppable={isDropable}
          icon={menuIcon()}
          level={0}
          dropOptions={dropContent}
          onClose={onClose}
          isNew={isNew}
        />

        {!isCollapsed &&
          dropContent?.map(drop => {
            if (isCollapsed) return <></>;
            return (
              <Collapse
                key={drop.name}
                in={option.fp.includes(name)}
                animateOpacity
              >
                <NavButton
                  handlePick={() => handlePick(2, drop.name)}
                  isActive={handleActive(drop.path)}
                  activeColor="mutable.200"
                  name={drop.name}
                  path={drop.path}
                  option={option}
                  isCollapsed={isCollapsed}
                  isDroppable={!!drop.insideDrops}
                  level={1}
                  dropOptions={drop.insideDrops}
                  onClose={onClose}
                  isNew={isNew}
                />

                <Collapse
                  key={drop.name}
                  in={option.sp.includes(drop.name)}
                  animateOpacity
                >
                  {drop.insideDrops?.map(inside => {
                    return (
                      <NavButton
                        key={inside.path}
                        isActive={handleActive(inside.path)}
                        activeColor="mutable.200"
                        name={inside.name}
                        path={inside.path}
                        option={option}
                        isCollapsed={isCollapsed}
                        isDroppable={false}
                        level={2}
                        onClose={onClose}
                        isNew={isNew}
                      />
                    );
                  })}
                </Collapse>
              </Collapse>
            );
          })}
      </Box>
    </Menu>
  );
};
