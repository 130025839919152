import React, { ReactNode, useMemo } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useAppSelector } from '@spartalabs/pdc-core';
import theme from 'theme';

interface ColorProviderProps {
  children: ReactNode;
}

export const ColorProvider = ({ children }: ColorProviderProps) => {
  const color = useAppSelector(state => state.authSlicer.user.color);

  const mutableTheme = useMemo(() => {
    let colors = theme.colors;

    switch (color) {
      case 'red':
        colors = {
          ...colors,
          mutable: {
            100: '#ED1C24',
            200: '#AE0007',
            300: '#871015',
            400: '#BC161D',
            500: '#ED1C24',
          },
        };
        break;
      case 'black':
        colors = {
          ...colors,
          mutable: {
            100: '#303030',
            200: '#1D1D1D',
            300: '#303030',
            400: '#666666',
            500: '#999999',
          },
        };
        break;

      default:
        colors = {
          ...colors,
          mutable: {
            100: '#0A3E5C',
            200: '#072638',
            300: '#0A3E5C',
            400: '#135A83',
            500: '#1D7AB0',
          },
        };
    }

    return { ...theme, colors };
  }, [color]);

  return (
    <ChakraProvider resetCSS theme={mutableTheme}>
      {children}
    </ChakraProvider>
  );
};
