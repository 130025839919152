import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  Text,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react';
import { clearNotification, useAppDispatch } from '@spartalabs/pdc-core';
import { colors } from 'theme/components/colors';

interface ToastProps {
  step: number;
  action: string;
  errorText?: string;
  helpText?: string;
  successText?: string;
  isLast?: boolean;
}

export function useCustomToast() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleToast = ({
    step,
    action,
    errorText,
    helpText,
    successText,
    isLast,
  }: ToastProps) => {
    const options = (): UseToastOptions => {
      switch (step) {
        case 0:
          return {
            id: action,
            title: 'O serviço solicitado se encontra fora do ar',
            description:
              'O serviço [0] se encontra indisponível, se persistir contate o suporte',
            status: 'warning',
          };

        case 1:
          return {
            id: action,
            title: 'O serviço solicitado se encontra fora do ar',
            description:
              'O serviço [1] se encontra indisponível, se persistir contate o suporte',
            status: 'warning',
          };

        case 2:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              errorText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte [0]',
            status: 'error',
          };

        case 3:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              errorText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte [1]',
            status: 'error',
          };

        case 4:
          return {
            id: action,
            title: `Ocorreu um erro ao ${action}`,
            description:
              helpText ||
              'Ocorreu um erro inesperado, se persistir contate o suporte',
            status: 'error',
          };

        case 5:
          return {
            id: action,
            title: action,
            description: successText || '',
            status: 'success',
          };
        case 6:
          return {
            id: action,
            title: action,
            description: successText || errorText || '',
            status: 'error',
          };

        case 7:
          return {
            id: action,
            duration: null,
            position: 'top-right',
            isClosable: false,
            containerStyle: {
              marginTop: isLast ? '80px' : '20px',

              maxWidth: '100%',
            },
            render: () => (
              <Flex
                borderRadius="10px"
                alignItems="center"
                p={3}
                bg="white"
                borderBottom={`8px ${colors.button} solid`}
              >
                <Center
                  mr="8px"
                  borderRadius="full"
                  p="4px"
                  bg="grey.200"
                  position="absolute"
                  left={0}
                  cursor="pointer"
                  mb="64px"
                  onClick={() => {
                    dispatch(clearNotification(action));
                    toast.close(action);
                  }}
                >
                  <Icon
                    as={IoClose}
                    color="black"
                    h="16px"
                    w="16px"
                    alignSelf="center"
                  />
                </Center>
                <Center
                  w="52px"
                  mr="8px"
                  borderRadius="8px"
                  py="12px"
                  bg="green.200"
                >
                  <Icon
                    as={AiOutlineCheck}
                    color="white"
                    h="20px"
                    w="20px"
                    p="2px"
                    borderRadius="full"
                    alignSelf="center"
                    bg="#4bae4f"
                  />
                </Center>
                <Box>
                  <Text fontWeight={600} fontSize="18px" textColor="text.100">
                    Documento CRLV adicionado
                  </Text>
                  <Text fontSize="16px" textColor="text.100">
                    Um documento CRLV foi adicionado para a placa {action}
                  </Text>
                </Box>
                <Divider
                  ml="8px"
                  mr="16px"
                  aria-hidden="true"
                  borderWidth="1px"
                  borderColor="grey.600"
                  opacity="0.2"
                  orientation="vertical"
                  h="52px"
                />
                <Text
                  onClick={() => {
                    dispatch(clearNotification(action));
                    toast.close(action);
                    navigate(`/gestao/veiculos/${action}`);
                  }}
                  fontSize="20px"
                  textColor="mutable.100"
                  cursor="pointer"
                >
                  Conferir
                </Text>
              </Flex>
            ),
          };

        default:
          return {
            id: 'Erro',
            title: 'Ocorreu um erro inesperado',
            description:
              'Ocorreu um erro inesperado, se persistir contate o suporte',
            status: 'error',
          };
      }
    };

    if (!toast.isActive(action)) {
      toast({
        position: 'top',
        duration: 4000,
        isClosable: true,
        ...options(),
      });
    }
    if (step === 7 && toast.isActive(action))
      toast.update(action, { ...options() });
  };

  return { handleToast };
}
