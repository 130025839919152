import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  ScriptableContext,
  ChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js';
import { Loading } from 'components/Loading';
import { colors } from 'theme/components/colors';
import { defaultColorArray } from 'utils/constants';
import { defaultChartPlugin, getMaxFromArrays } from 'utils/functions';

const LineChart: React.FC<LineChartProps> = ({
  labels,
  data,
  options,
  fill = true,
  w,
  loading,
}) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: data.map((element, index) => {
      return {
        label: element.label,
        borderColor: element.borderColor
          ? element.borderColor
          : element.fill
          ? Array.isArray(element.fill.color)
            ? element.fill.color[0]
            : !element.fill.color
            ? colors.blue['300']
            : element.fill.color
          : defaultColorArray[index],
        fill: !!element.fill,
        data: element.values,
      } as ChartDataset<'line'>;
    }),
  });

  const chartDataFromData: ChartData<'line'> = useMemo(
    () => ({
      datasets: data.map((element, index) => {
        return {
          label: element.label,
          borderColor: element.borderColor
            ? element.borderColor
            : element.fill
            ? Array.isArray(element.fill.color)
              ? element.fill.color[0]
              : !element.fill.color
              ? colors.blue['300']
              : element.fill.color
            : defaultColorArray[index],
          fill: !!element.fill,
          data: element.values,
          datalabels: {
            color: element.borderColor,
            textStrokeColor: 'white',
            textStrokeWidth: 2,
            font: {
              weight: 'bold',
            },
            align: index % 2 ? -41 : 220,
            offset: 0,
            clip: false,
            formatter: value => {
              return value ? (labels.length < 32 ? value : null) : null;
            },
          },
        } as ChartDataset<'line'>;
      }),
    }),
    [data, labels],
  );

  const yMaxValue = useMemo(() => {
    const values = data.map(dataset => dataset.values);

    const maxValueFromData = getMaxFromArrays(values as number[][]);

    return Math.ceil(maxValueFromData + maxValueFromData * 0.1);
  }, [data]);

  const defaultOptions: ChartOptions<'line'> = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: defaultChartPlugin(data.length),
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: true,
          },
          max: yMaxValue,
        },
      },
    }),
    [data],
  );

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    setChartData({
      labels,
      grouped: true,
      datasets: chartDataFromData.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          if (!fill) {
            return 'transparent';
          }
          if (data[index].fill && Array.isArray(data[index].fill?.color)) {
            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top,
            );

            gradient.addColorStop(1, data[index].fill?.color?.[0] || 'white');
            gradient.addColorStop(0, data[index].fill?.color?.[1] || 'white');

            return gradient;
          }

          if (data[index].fill && !data[index].fill?.color) {
            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top,
            );

            gradient.addColorStop(1, colors.blue['100']);
            gradient.addColorStop(0, 'rgba(86, 185, 242, 0.3)');

            return gradient;
          }

          if (data[index].fill && !Array.isArray(data[index].fill?.color))
            return data[index].fill?.color || 'white';

          return (
            dataset.borderColor ||
            defaultColorArray[index].toString() ||
            'white'
          );
        },
      })),
    } as ChartData<'line'>);
  }, [chartDataFromData]);

  return (
    <>
      {loading && <Loading />}
      <Box
        h="100%"
        w={w ? { base: w, sm: '98%' } : '96%'}
        opacity={loading ? '50%' : '100%'}
      >
        <Line
          aria-label="Gráfico de linha"
          ref={chartRef}
          options={options ? { ...defaultOptions, ...options } : defaultOptions}
          data={chartData}
        />
      </Box>
    </>
  );
};

export default LineChart;
