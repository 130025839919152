import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { AiTwotoneCalendar } from 'react-icons/ai';
import {
  Box,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  BoxProps,
  Button,
  VStack,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react';
import {
  setGlobalDate,
  useAppDispatch,
  updatesGlobalDate,
} from '@spartalabs/pdc-core';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import { pt } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { colors } from 'theme/components/colors';
import { checkDateRange } from 'utils/functions';
import MonthPicker from './components/monthPicker';

interface QuickButtonProps extends BoxProps {
  handleOnClick: () => void;
  label: string;
  testName?: string;
}

const QuickButton: React.FC<QuickButtonProps> = ({
  handleOnClick,
  label,
  testName,
}) => {
  return (
    <Text
      aria-label={`Filtrar ${label}`}
      data-cy={testName}
      py="4px"
      px="8px"
      transition="all 0.2s"
      as="button"
      border="1px solid"
      borderColor="transparent"
      borderRadius="4px"
      _active={{ borderColor: 'button', bg: 'button', color: 'white' }}
      _hover={{
        borderColor: 'button',
      }}
      textAlign="left"
      onClick={handleOnClick}
      fontSize="sm"
      color="text.100"
      w="100%"
    >
      {label}
    </Text>
  );
};

interface DateRangeCompProps extends BoxProps {
  startDate?: DateProps[];
  type?: string;
  isOnHeader?: boolean;
  dateName?: string;
}

const DateRangeComp = forwardRef<DateProps[], DateRangeCompProps>(
  (props, ref) => {
    const dispatch = useAppDispatch();

    const { startDate, type, isOnHeader, dateName, ...rest } = props;

    const [pickedRange, setPickedRange] = useState<DateProps[]>(
      startDate || [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 10),
          key: 'selection',
        },
      ],
    );

    useEffect(() => {
      if (startDate) setPickedRange(startDate);
    }, [startDate]);

    const [range, setRange] = useState<DateProps[]>(
      startDate || [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 10),
          key: 'selection',
        },
      ],
    );

    const [hover, setHover] = useState<DataHover>({
      hoveredDate: null,
      isHovering: false,
    });

    const handleDate = useCallback((type: string) => {
      const date = new Date();

      setHover({ hoveredDate: null, isHovering: false });

      switch (type) {
        case 'atual':
          return setRange([
            {
              startDate: new Date(date.getFullYear(), date.getMonth(), 1),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'lastMonth':
          return setRange([
            {
              startDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'last3Months':
          return setRange([
            {
              startDate: new Date(date.getFullYear(), date.getMonth() - 3, 1),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'last6Months':
          return setRange([
            {
              startDate: new Date(date.getFullYear(), date.getMonth() - 6, 1),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'lastYear':
          return setRange([
            {
              startDate: new Date(date.getFullYear() - 1, date.getMonth(), 1),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'last3Days':
          return setRange([
            {
              startDate: addDays(new Date(), -3),
              endDate: new Date(),
              key: 'selection',
            },
          ]);
        case 'lastWeek':
          return setRange([
            {
              startDate: addDays(new Date(), -7),
              endDate: new Date(),
              key: 'selection',
            },
          ]);

        default:
          return;
      }
    }, []);

    const menu = useDisclosure();

    useImperativeHandle(ref, () => pickedRange ?? new Date(), [pickedRange]);

    return (
      <Box {...rest}>
        <Menu {...menu}>
          <MenuButton
            aria-label={`Filtrar por data ${format(
              checkDateRange(pickedRange)[0]?.startDate || new Date(),
              'dd/MM/yyyy',
            )} ~ ${format(
              checkDateRange(pickedRange)[0]?.endDate || new Date(),
              'dd/MM/yyyy',
            )}`}
            data-cy={`date-${dateName}`}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          >
            <Flex
              alignItems="center"
              py="8px"
              px="4px"
              border="1px solid"
              borderColor="grey.500"
              borderRadius="4px"
              bg={isOnHeader ? 'white' : 'transparent'}
            >
              <AiTwotoneCalendar
                size={14}
                color={isOnHeader ? colors.text[100] : colors.grey[500]}
              />

              <Text
                data-cy={`date-text-${dateName}`}
                ml="9px"
                fontSize="xs"
                color="text.100"
              >
                {`${format(
                  checkDateRange(pickedRange)[0]?.startDate || new Date(),
                  'dd/MM/yyyy',
                )} ~ ${format(
                  checkDateRange(pickedRange)[0]?.endDate || new Date(),
                  'dd/MM/yyyy',
                )}`}
              </Text>
            </Flex>
            <Box display={{ base: 'flex', xl: 'none' }}></Box>
          </MenuButton>

          <MenuList bg="white" overflow="hidden" maxW="95vw">
            <Box display="flex" flexDirection="row" mt="-60px">
              {type === 'Dia' ? (
                <Box aria-hidden="true">
                  <DateRange
                    locale={pt}
                    ranges={checkDateRange(range)}
                    onChange={item =>
                      setRange(
                        item.default
                          ? [{ ...item.default, key: 'selection' }]
                          : [item.selection],
                      )
                    }
                  />
                </Box>
              ) : (
                <>
                  <MonthPicker
                    hover={hover}
                    setHover={setHover}
                    name={dateName}
                    range={checkDateRange(range)}
                    setRange={setRange}
                  />
                </>
              )}
              <Box>
                <VStack
                  h="83%"
                  borderLeft="1px solid gray"
                  borderColor="grey.200"
                  alignItems="start"
                  justifyContent="initial"
                  px="16px"
                  mt="60px"
                  divider={<StackDivider borderColor="grey.200" />}
                >
                  {type === 'Dia' ? (
                    <VStack
                      alignItems="start"
                      divider={<StackDivider borderColor="grey.200" />}
                    >
                      <QuickButton
                        testName={`3d-${dateName}`}
                        handleOnClick={() => handleDate('last3Days')}
                        label="Últimos 3 dias"
                      />
                      <QuickButton
                        testName={`7d-${dateName}`}
                        handleOnClick={() => handleDate('lastWeek')}
                        label="Últimos 7 dias"
                      />
                    </VStack>
                  ) : (
                    <VStack
                      alignItems="start"
                      divider={<StackDivider borderColor="grey.200" />}
                    >
                      <QuickButton
                        testName={`1m-${dateName}`}
                        handleOnClick={() => handleDate('atual')}
                        label="Mês atual"
                      />
                      <QuickButton
                        testName={`lm-${dateName}`}
                        handleOnClick={() => handleDate('lastMonth')}
                        label="Último mês"
                      />
                      <QuickButton
                        testName={`3m-${dateName}`}
                        handleOnClick={() => handleDate('last3Months')}
                        label="Últimos 3 meses"
                      />
                      <QuickButton
                        testName={`6m-${dateName}`}
                        handleOnClick={() => handleDate('last6Months')}
                        label="Últimos 6 meses"
                      />
                      <QuickButton
                        testName={`12m-${dateName}`}
                        handleOnClick={() => handleDate('lastYear')}
                        label="Últimos 12 meses"
                      />
                    </VStack>
                  )}
                  <Button
                    data-cy={`date-aply-${dateName}`}
                    onClick={() => {
                      setPickedRange(range);

                      if (isOnHeader) {
                        dispatch(setGlobalDate(range));
                        dispatch(updatesGlobalDate(true));
                      }

                      menu.onClose();
                    }}
                    mt="auto"
                  >
                    Aplicar
                  </Button>
                </VStack>
              </Box>
            </Box>
          </MenuList>
        </Menu>
      </Box>
    );
  },
);

DateRangeComp.displayName = 'DateRangeComp';

export default DateRangeComp;
