import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FlexProps, Icon, Text, Button } from '@chakra-ui/react';
import { Card } from './Card';

interface ErrorCard extends FlexProps {
  backTo?: string;
}

export const EmptyCard: React.FC<ErrorCard> = ({ backTo, ...rest }) => {
  const navigate = useNavigate();
  return (
    <Card {...rest} py="28px" align="center">
      <Icon
        borderRadius="100px"
        padding="16px"
        bg="grey.200"
        w="80px"
        h="80px"
        as={AiOutlineFileSearch}
        color="grey.600"
      />
      <Text mt="16px" fontWeight="medium" color="text.100" fontSize="20px">
        Não foram encontradas informações
      </Text>
      <Text color="text.300" fontSize="16px">
        {!backTo ? '' : ' Volte para a pagina anterior'}
      </Text>
      {backTo && (
        <Button
          mt="12px"
          fontSize="14px"
          w="98px"
          variant="outline"
          onClick={() => navigate(backTo)}
        >
          Voltar
        </Button>
      )}
    </Card>
  );
};
