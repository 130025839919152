/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';
import { Flex, HStack, IconButton, Select, Text } from '@chakra-ui/react';

interface PaginatorProps {
  setFilters: any;
  page: number;
  quantity: number;
  total: number;
  lastPage: number;
}

export const Paginator: React.FC<PaginatorProps> = ({
  setFilters,
  page,
  quantity,
  total,
  lastPage = 5,
}) => {
  return (
    <Flex mt="20px" width="100%" alignItems="center" justifyContent="center">
      <HStack
        flexDir={{ base: 'column', sm: 'row' }}
        w="100%"
        justify="space-between"
      >
        <Text color="text.300" fontSize="14px">
          Mostrando de {quantity * (page - 1) + 1} até {page * quantity} de{' '}
          {total} registros
        </Text>

        <Flex>
          <Select
            aria-label="Quantidade de linhas"
            color="grey.600"
            onChange={e =>
              setFilters((filter: any) => ({
                ...filter,
                quantity: e.target.value,
              }))
            }
          >
            {[5, 10, 15, 20, 25].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} Linhas
              </option>
            ))}
          </Select>

          <IconButton
            icon={<AiOutlineDoubleLeft />}
            aria-label="Primeira página"
            variant="ghost"
            color="grey.600"
            onClick={() =>
              setFilters((filter: any) => ({ ...filter, page: 1 }))
            }
            isDisabled={page === 1}
          />
          <IconButton
            icon={<AiOutlineLeft />}
            aria-label="Voltar página"
            variant="ghost"
            color="grey.600"
            onClick={() =>
              setFilters((filter: any) => ({
                ...filter,
                page: filter.page - 1,
              }))
            }
            isDisabled={page === 1}
          />
          <IconButton
            icon={<AiOutlineRight />}
            aria-label="Avançar página"
            variant="ghost"
            color="grey.600"
            onClick={() =>
              setFilters((filter: any) => ({
                ...filter,
                page: filter?.page ? filter.page + 1 : 2,
              }))
            }
            isDisabled={page === lastPage}
          />
          <IconButton
            icon={<AiOutlineDoubleRight />}
            aria-label="Última página"
            variant="ghost"
            color="grey.600"
            onClick={() =>
              setFilters((filter: any) => ({ ...filter, page: lastPage }))
            }
            isDisabled={page === lastPage}
          />
        </Flex>
      </HStack>
    </Flex>
  );
};
