import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  ScriptableContext,
  ChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js';
import { Loading } from 'components/Loading';
import { defaultColorArray } from 'utils/constants';
import { defaultChartPlugin } from 'utils/functions';

const BarChart: React.FC<BarChartProps> = ({
  labels,
  data,
  options,
  stackedX = false,
  stackedY = false,
  w,
  loading,
}) => {
  const defaultOptions: ChartOptions<'bar'> = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      plugins: defaultChartPlugin(data.length),
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: true,
          },
        },
      },
    }),
    [data],
  );

  const stackedOptions = {
    ...defaultOptions,
    scales: {
      x: { stacked: stackedX, grid: { display: false } },
      y: { stacked: stackedY, grid: { display: false } },
    },
  };

  const chartRef = useRef<ChartJS<'bar'>>(null);
  const [chartData, setChartData] = React.useState<ChartData<'bar'>>({
    datasets: data.map((element, index) => {
      return {
        label: element.label,
        borderColor: element.borderColor
          ? element.borderColor
          : element.fill
          ? Array.isArray(element.fill.color)
            ? element.fill.color[0]
            : element.fill.color
          : defaultColorArray[index],
        fill: !!element.fill,
        data: element.values,
      } as ChartDataset<'bar'>;
    }),
  });

  const chartDataFromData: ChartData<'bar'> = useMemo(
    () => ({
      datasets: data.map((element, index) => {
        return {
          label: element.label,
          borderColor: element.borderColor
            ? element.borderColor
            : element.fill
            ? Array.isArray(element.fill.color)
              ? element.fill.color[0]
              : element.fill.color
            : defaultColorArray[index],
          fill: !!element.fill,
          data: element.values,
        } as ChartDataset<'bar'>;
      }),
    }),
    [data, labels],
  );

  React.useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    setChartData({
      labels,
      grouped: true,
      datasets: chartDataFromData.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          if (data[index].fill && Array.isArray(data[index].fill?.color)) {
            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top,
            );

            gradient.addColorStop(1, data[index].fill?.color?.[0] || 'white');
            gradient.addColorStop(0, data[index].fill?.color?.[1] || 'white');
            return gradient;
          }

          if (data[index].fill && data[index].fill?.color)
            return data[index].fill?.color || 'white';

          return defaultColorArray[index].toString() || 'white';
        },
      })),
    } as ChartData<'bar'>);
  }, [chartDataFromData]);

  return (
    <>
      {loading && <Loading />}
      <Box
        h="100%"
        w={w ? { base: w, md: '98%' } : '98%'}
        opacity={loading ? '50%' : '100%'}
      >
        <Bar
          aria-label="Grafico de barra"
          ref={chartRef}
          options={options ? { ...stackedOptions, ...options } : stackedOptions}
          data={chartData}
        />
      </Box>
    </>
  );
};

export default BarChart;
