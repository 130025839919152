import React from 'react';
import { Box, FlexProps } from '@chakra-ui/react';

interface HorizontalBarProps extends FlexProps {
  total: number;
  value: number;
  color?: string;
}

export const HorizontalBar: React.FC<HorizontalBarProps> = ({
  total,
  value,
  color,
  ...rest
}) => {
  return (
    <Box {...rest} bg="grey.200" borderRadius="4px">
      <Box
        bg={color || 'blue.100'}
        h={rest.h}
        w={value ? `${(value * 100) / total}%` : 0}
        borderRadius="4px"
      />
    </Box>
  );
};
