import React from 'react';
import { Flex, HStack, Box, Skeleton } from '@chakra-ui/react';
import { MapsSvg } from 'components';
import { CardSkele } from '../Card';

interface MapSkeletonProps {
  w?: string;
}

export const MapSkeleton: React.FC<MapSkeletonProps> = ({ w }) => {
  return (
    <CardSkele
      h={{ base: '100%', lg: w === '50%' ? '374px' : '100%' }}
      w={{ base: '100%', lg: w }}
    >
      <Flex zIndex={0} my="24px" mx="20px" flexDir="column" h="100%">
        <Flex justifyContent="space-between">
          <Skeleton w="134px" h="20px" />
          <HStack>
            <Skeleton w="85px" h="20px" />
            <Skeleton w="40px" h="28px" />
          </HStack>
        </Flex>

        <Skeleton mb="8px" w="190px" h="12px" />

        <HStack display={{ base: 'flex', md: 'none' }} spacing="32px"></HStack>

        <Flex
          flex={1}
          // h="100%"
          flexDirection={{ base: 'column', md: 'row' }}
          align="center"
        >
          <MapsSvg mapH="160px" setSelected={() => null} />

          <Box
            mt={{ base: '34px', md: '0px' }}
            justifyContent="center"
            alignItems="center"
            ml="20px"
            w={{ base: '96%', lg: '76%' }}
          >
            <Skeleton h="18px"></Skeleton>
            <Skeleton mt="8px" h="18px"></Skeleton>
            <Skeleton mt="8px" h="18px"></Skeleton>
            <Skeleton mt="8px" h="18px"></Skeleton>
            <Skeleton mt="8px" h="18px"></Skeleton>
          </Box>
        </Flex>
      </Flex>
    </CardSkele>
  );
};
