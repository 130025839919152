import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: ' grey.200',
    borderRadius: '4px',
    fontSize: '14px',
    color: 'text.100',
    _placeholder: { color: 'grey.500' },
  },
});

const sizes = {
  md: definePartsStyle({
    field: { fontSize: '14px', py: '14px', px: '18px', h: '44px' },
  }),
};

const variants = {
  default: definePartsStyle({
    field: {
      bg: 'white',
      color: 'grey.600',
      _placeholder: { color: 'grey.500' },
    },
  }),
  dark: definePartsStyle({
    field: {
      color: 'grey.500',
      _placeholder: { color: 'grey.600' },
    },
  }),
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: { size: 'md', variant: 'default' },
});
