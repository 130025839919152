import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { CardSkele } from '../Card';

export const VehicleSkeleton: React.FC = () => {
  return (
    <>
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <CardSkele h="20px" mt="52px" w="120px">
          <Skeleton w="120px" h="20px" />
        </CardSkele>

        <CardSkele mt="52px" w="120px">
          <Skeleton w="120px" h="32px" />
        </CardSkele>
      </Flex>

      <CardSkele w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkele>
      <CardSkele w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkele>
      <CardSkele w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkele>
    </>
  );
};
