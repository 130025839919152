import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    padding: 2, // change the padding of the control
    borderRadius: '4px', // change the border radius of the control
    bg: 'white',
  },
});

export const Checkbox = defineMultiStyleConfig({ baseStyle });
