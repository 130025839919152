import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { DelayAnimation } from 'shared/DelayAnimation';

interface ContainerProps extends BoxProps {
  children: ReactNode;
}

export const Container: React.FC<ContainerProps> = props => {
  return (
    <DelayAnimation>
      <Box
        mt="32px"
        borderRadius="4px"
        minH="80vh"
        mx={{ base: '16px', md: '32px' }}
        pb="32px"
        pt="24px"
        {...props}
      >
        {props.children}
      </Box>
    </DelayAnimation>
  );
};
