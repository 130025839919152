import React, { ReactNode, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  addWidget,
  reorderCards,
  reorderRows,
  useAppDispatch,
  useAppSelector,
} from '@spartalabs/pdc-core';
import { useCustomToast } from 'hooks';
import useAnalyticsEvent from 'hooks/useAnalyticsEvent';
import { GA_CUSTOMIZE_WIDGETS_LABEL } from 'utils/constants';
import { getWidgetById } from 'utils/functions';

interface GlobalDndProps {
  children: ReactNode;
}

export const GlobalDnd: React.FC<GlobalDndProps> = ({ children }) => {
  const dashboardCards = useAppSelector(
    state => state.globalDataSlicer.dashboardCards,
  );
  const dispatch = useAppDispatch();
  const { handleToast } = useCustomToast();

  const analyticsEvent = useAnalyticsEvent({
    category: 'User',
    action: GA_CUSTOMIZE_WIDGETS_LABEL,
  });

  const move = useCallback(
    (drop: DropResult) => {
      //ADDS A NEW WIDGET
      if (
        drop.source?.droppableId === 'widgets' &&
        drop.destination?.droppableId === 'DroppableRows'
      ) {
        const widgetId = Number(drop.draggableId.replace(/[^\d.-]/g, ''));
        analyticsEvent(`Widget Adicionado: ${getWidgetById(widgetId)}`);
        return dispatch(
          addWidget({
            id: widgetId,
            index: drop.destination?.index,
          }),
        );
      }

      //MOVES ENTIRE ROW
      if (
        drop.destination?.droppableId.includes('DroppableRows') &&
        drop.source?.droppableId.includes('DroppableRows') &&
        drop.destination?.droppableId === drop.source?.droppableId
      ) {
        return dispatch(
          reorderRows({
            prevIndex: drop.source?.index,
            newIndex: drop.destination?.index,
          }),
        );
      }

      //MOVES A SINGLE CARD
      if (
        drop.destination?.droppableId.includes('DroppableRow') &&
        drop.source?.droppableId.includes('DroppableRow')
      ) {
        //MOVES A SINGLE CARD ON THE SAME ROW
        if (drop.destination?.droppableId === drop.source?.droppableId)
          return dispatch(
            reorderCards({
              rowIndex: Number(
                drop.destination?.droppableId.replace(/[^\d.-]/g, ''),
              ),
              newIndex: drop.destination?.index,
              prevIndex: drop.source?.index,
            }),
          );

        if (
          dashboardCards[
            Number(drop.destination?.droppableId.replace(/[^\d.-]/g, ''))
          ].content.length > 1
        )
          return handleToast({
            step: 4,
            action: 'realizar essa ação',
            helpText: 'Não é possível posicionar três quadros na mesma linha',
          });

        //MOVES A SINGLE CARD TO ANOTHER ROW
        if (
          !dashboardCards[
            Number(drop.destination?.droppableId.replace(/[^\d.-]/g, ''))
          ].content[0].canBeHalf
        )
          return handleToast({
            step: 4,
            action: 'realizar essa ação',
            helpText: 'O quadro da linha de destino tem tamanho fixo de 100%',
          });

        return dispatch(
          reorderCards({
            rowIndex: Number(drop.source?.droppableId.replace(/[^\d.-]/g, '')),
            newIndex: drop.destination?.index,
            prevIndex: drop.source?.index,
            newRowIndex: Number(
              drop.destination?.droppableId.replace(/[^\d.-]/g, ''),
            ),
          }),
        );
      }
    },
    [dashboardCards],
  );

  return <DragDropContext onDragEnd={move}>{children}</DragDropContext>;
};
